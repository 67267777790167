import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CloseRounded } from '@mui/icons-material';
import { ContentWrapperBox, HeadingWrapper } from './CreateEditInteractionRuleFragment.styles';
import { Modal } from '../../../../components/atoms/Modal';
import { TextField } from '../../../../components/atoms/TextField';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { SelectionButton } from '../../../../components/molecules/SelectionButton';
import { MultiSelectCheckmarksMenu } from '../../../../components/atoms/MultiSelectCheckmarksMenu';
import { Switch } from '../../../../components/atoms/Switch';
import { MultiClientSelector } from '../../../../components/molecules/MultiClientSelector';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { TypographyVariantProps } from '../../../../components/atoms/Typography';
import { ColorProps, NodeType, PrioritySelectionObjectType, ServiceCommonType } from '../../../../@types';
import { PART_ACTION_CONDITION_JOIN_OPTIONS, PART_ACTION_CONDITION_OPERATORS, PART_TYPE_OPTIONS, SAMPLE_INTERACTIONS_SERVICE_COMMON_TYPE, SAMPLE_INTERACTION_RULE_OBJECT, SAMPLE_PRIORITY_OBJECT, SYSTEM_EVENT_MENU_OPTIONS, TEXT_REFERENCE_MENU_OPTIONS, TRIGGER_POINT_MENU_OPTIONS } from '../../../../constants/interaction.constant';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { CreateEditInteractionRuleFragmentProps } from './CreateEditInteractionRuleFragment.props';
import { ANY_ITEM_ARRAY, ANY_KEYWORD } from '../../../../constants/common';
import { InteractionRuleListObject, InteractionRuleObject, InteractionRulePartActionConditionType } from '../../../../@types/interaction.type';
import { useGetServiceActionProcessTypes } from '../../../../queries/service-query';
import { actionTypesWithAnyWildcard, availableStatusesWithAnyWildcard, prioritiesWithAnyWildcard, processTypesWithAnyWildcard, serviceTypesWithAnyWildcard } from '../../../../utils/services';
import { useGetFilteredNodeAttributesByCustomRange, useGetNodeChildrenByNodeType, useGetPrioritySelection, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../../queries/structure-query';
import { InteractionRuleSystemEventType, InteractionRuleTriggerPointType, NodeAttributeType, StructureTypeCategory } from '../../../../configs/enums';
import { useGetRegionTypes } from '../../../../queries/regionTypes-query';
import { useCreateInteractionRule, useGetAllActiveInteractionTemplates, useUpdateInteractionRule } from '../../../../queries/interaction-rule-query';
import { convertHyphenTextToReadText, convertToArray, isEmptyString } from '../../../../utils/common';
import { useGetServiceTypeAvailableStatuses } from '../../../../queries/service-type-query';
import { TaskType } from '../../../../@types/task.type';
import { setDropDownList } from '../../../../utils/interaction-rule';
import { CloseIcon } from '../../../../components/atoms/CloseIcon';
import { useGetPartActionTypes } from '../../../../queries/partAction-query';
import { useGetInteractionRuleById } from '../../../../queries/interactions-rules-config-query';
import { CheckItem } from '../../../../components/molecules/CheckItem';
import { NEW_STATUS_DROPDOWN_ELEMENT } from '../../../../constants/serviceStatus.constant';

export const CreateEditInteractionRuleFragment: React.FC<CreateEditInteractionRuleFragmentProps> = ({
  openModal,
  isEdit,
  interactionRuleId,
  handleClose,
}) => {
  const activeOnly = true;
  const [openModel, setOpenModel] = useState(false);
  const [interactionRuleConfigData, setInteractionRuleConfigData] = useState<InteractionRuleObject>(SAMPLE_INTERACTION_RULE_OBJECT);
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [clients, setClients] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [contracts, setContracts] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [brands, setBrands] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [models, setModels] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [statusChangeOpen, setStatusChangeOpen] = useState<boolean>(false);
  const [systemEventOpen, setSystemEventOpen] = useState<boolean>(false);
  const [serviceCommonTypes, setServiceCommonTypes] = useState<ServiceCommonType>(SAMPLE_INTERACTIONS_SERVICE_COMMON_TYPE);
  const [availablePriorityList, setAvailablePriorityList] = useState<DropdownItem[]>([]);
  const [prioritySelectionObject, setPrioritySelectionObject] = useState<PrioritySelectionObjectType>(SAMPLE_PRIORITY_OBJECT);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState('client');
  const [parentId, setParentId] = useState('');
  const [nodeAttributeTypes, setNodeAttributeTypes] = useState<NodeAttributeType[]>([NodeAttributeType.Models]);
  const [selectedParentIdForModel, setSelectedParentIdForModel] = useState('');
  const [serviceTypeCode, setServiceTypeCode] = useState('');
  const [regionTypeList, setRegionTypeList] = useState<DropdownItem[]>([]);
  const [interactionTemplateList, setInteractionTemplateList] = useState<DropdownItem[]>([]);
  const [interactionRuleListData, setInteractionRuleListData] = useState<InteractionRuleListObject>();
  const [availableStatusList, setAvailableStatusList] = useState<DropdownItem[]>([]);
  const [partActionExecutionOpen, setPartActionExecutionOpen] = useState<boolean>(false);
  const [taskExecutionOpen, setTaskExecutionOpen] = useState<boolean>(false);
  const [partActionTypes, setPartActionTypes] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [taskTypes, setTaskTypes] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [textReferenceTypes, setTextReferenceTypes] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [interactionRuleData, setInteractionRuleData] = useState<InteractionRuleObject>();

  const serviceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);
  const getPrioritySelection = useGetPrioritySelection();
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const regionTypesQuery = useGetRegionTypes();
  const getInteractionTemplateListQuery = useGetAllActiveInteractionTemplates();
  const getServiceTypeAvailableStatusesQuery = useGetServiceTypeAvailableStatuses();
  const getNodeAttributesByCustomRange = useGetFilteredNodeAttributesByCustomRange(selectedParentIdForModel, nodeAttributeTypes, serviceTypeCode);
  const createInteractionRuleQuery = useCreateInteractionRule();
  const updateInteractionRuleQuery = useUpdateInteractionRule();
  const getPartActionTypes = useGetPartActionTypes();
  const getInteractionRuleById = useGetInteractionRuleById(interactionRuleId || 0);

  useEffect(() => {
    isEdit && getInteractionRuleById.refetch();
  }, [isEdit]);

  useEffect(() => {
    getInteractionRuleById.data && setInteractionRuleData(getInteractionRuleById.data);
  }, [getInteractionRuleById.data]);

  useEffect(() => {
    if (openModal && (interactionRuleData || !isEdit)) {
      handleModelOpen();
    }

    if (interactionRuleData?.triggerPoint === InteractionRuleTriggerPointType.StatusChange) {
      setStatusChangeOpen(true);
      setSystemEventOpen(false);
      setTaskExecutionOpen(false);
      setPartActionExecutionOpen(false);
    } else if (interactionRuleData?.triggerPoint === InteractionRuleTriggerPointType.SystemEvent) {
      setSystemEventOpen(true);
      setStatusChangeOpen(false);
    }

    if (interactionRuleData?.eventType === InteractionRuleSystemEventType.PartActionExecution) {
      setPartActionExecutionOpen(true);
      setTaskExecutionOpen(false);
    } else if (interactionRuleData?.eventType === InteractionRuleSystemEventType.TaskExecution) {
      setTaskExecutionOpen(true);
      setPartActionExecutionOpen(false);
    }
  }, [openModal, interactionRuleData, isEdit]);

  useEffect(() => {
    serviceActionProcessTypesQuery.data && setServiceCommonTypes(serviceActionProcessTypesQuery.data);
  }, [serviceActionProcessTypesQuery.data]);

  useEffect(() => {
    if (prioritySelectionObject?.client && prioritySelectionObject?.contract && prioritySelectionObject?.brand && prioritySelectionObject?.serviceTypes) {
      getPriorities(prioritySelectionObject);
    }
  }, [prioritySelectionObject]);

  useEffect(() => {
    if (selectedParentId && queryNodeType && selectedParentId !== 'any' && selectedParentId.split(',').length === 1) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.isSuccess) {
      setClients(ANY_ITEM_ARRAY.concat(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
    }

  }, [getStructuresByTypeCategoryCodeAndTypeCode.isLoading, getStructuresByTypeCategoryCodeAndTypeCode.isSuccess]);

  useEffect(() => {
    if ((getNodeChildrenByNodeType.isSuccess || getNodeChildrenByNodeType.isRefetching) && getNodeChildrenByNodeType.data) {
      mapData(getNodeChildrenByNodeType.data);
    }
  }, [getNodeChildrenByNodeType.isLoading, getNodeChildrenByNodeType.isSuccess, getNodeChildrenByNodeType.isRefetching]);

  useEffect(() => {
    (interactionRuleData?.partActionType || partActionExecutionOpen) && getPartActionTypes.refetch();
  }, [partActionExecutionOpen, interactionRuleData?.partActionType]);

  useEffect(() => {
    if (getPartActionTypes.data) {
      setPartActionTypes(ANY_ITEM_ARRAY.concat(getPartActionTypes.data.map(action => ({
        value: action.code,
        label: action.name
      }))));
    }
  }, [getPartActionTypes.data]);

  useEffect(() => {
    if (interactionRuleData?.taskType || taskExecutionOpen) {
      setTaskTypes(ANY_ITEM_ARRAY.concat(Object.values(TaskType).map(type => ({
        value: type,
        label: convertHyphenTextToReadText(type)
      }))));
    }
  }, [taskExecutionOpen, interactionRuleData?.taskType]);

  useEffect(() => {
    setTextReferenceTypes(ANY_ITEM_ARRAY.concat(TEXT_REFERENCE_MENU_OPTIONS));
  }, [interactionRuleData?.textReferenceType]);

  useEffect(() => {
    if (regionTypesQuery.data) {
      const regionTypesItems: DropdownItem[] = [];
      const anyWildCard = { value: 'any', label: 'Any' };
      regionTypesItems.push(anyWildCard);
      regionTypesQuery?.data?.map((item) => {
        const regionType = {
          value: item.code,
          label: item.name
        };
        regionTypesItems.push(regionType);
      });
      setRegionTypeList(regionTypesItems);
    }
  }, [regionTypesQuery.data]);

  useEffect(() => {
    if (getInteractionTemplateListQuery?.data) {
      const interactionTemplateListItems: DropdownItem[] = [];
      getInteractionTemplateListQuery?.data?.map((item: any) => {
        const templateList = {
          value: item.id.toString(),
          label: item.name
        };
        interactionTemplateListItems.push(templateList);
      });
      setInteractionTemplateList(interactionTemplateListItems); 
    }
  }, [getInteractionTemplateListQuery.data]);
  

  useEffect(() => {
    const str = parentId;
    const stringArray: string[] = str.split(',');
    stringArray.length === 1 && !stringArray.includes(ANY_KEYWORD) && setSelectedParentIdForModel(stringArray[0]);
    stringArray.length > 1 && setModels(ANY_ITEM_ARRAY);
  }, [parentId]);

  useEffect(() => {
    selectedParentIdForModel && selectedParentIdForModel !== 'any' && serviceTypeCode && getNodeAttributesByCustomRange.refetch();
  }, [selectedParentIdForModel, serviceTypeCode]);

  useEffect(() => {
    if (getNodeAttributesByCustomRange?.data) {
      const modelList: DropdownItem[] = [];
      getNodeAttributesByCustomRange?.data?.map((item: any) => {
        const model = {
          value: item.id.toString(),
          label: item.value.clientModelName
        };
        modelList.push(model);
      });
      setModels([...ANY_ITEM_ARRAY, ...modelList]); 
    }
  }, [getNodeAttributesByCustomRange.data]);
  
  const handleModelOpen = () => {
    if (interactionRuleData) {
      setSelectedParentId(interactionRuleData.client[0]);
      setSelectedParentIdForModel(interactionRuleData.contract[0] !== ANY_KEYWORD ? interactionRuleData.contract[0] : interactionRuleData.client[0]);
      getStructuresByTypeCategoryCodeAndTypeCode.refetch();
      setQueryNodeType(NodeType.Contract);
      getNodeChildrenByNodeType.refetch();
      onChangeServiceTypes({ serviceTypes: interactionRuleData.serviceTypes || [] });
      onChangePriorities({ 
        serviceTypes: interactionRuleData.serviceTypes,
        client: interactionRuleData.client,
        contract: interactionRuleData.contract,
        brand: interactionRuleData.brand
      });
      onChangeServiceTypes({ serviceTypes: interactionRuleData.serviceTypes || [] });
      interactionRuleData.serviceTypes.length === 1 && interactionRuleData.serviceTypes[0] !== 'any' && setServiceTypeCode(interactionRuleData.serviceTypes[0]);
      setInteractionRuleConfigData(interactionRuleData);
    }
    setOpenModel(true);
  }; 

  const mapData = async (data: any) => {
    if (queryNodeType == NodeType.Contract) {
      setContracts(ANY_ITEM_ARRAY.concat(data.map((item: any) => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
      setSelectedParentId(interactionRuleConfigData?.contract[0]);
      setSelectedParentIdForModel(interactionRuleConfigData?.contract[0]);
      setQueryNodeType(NodeType.Brand);
    }
    else if (queryNodeType == NodeType.Brand) {
      setBrands(ANY_ITEM_ARRAY.concat(data.map((item: any) => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
    }
  }; 

  const onChangePriorities = async (prioritySelectionValue: PrioritySelectionObjectType) => {
    setPrioritySelectionObject(prioritySelectionValue);
  };

  const getPriorities = async (value: PrioritySelectionObjectType) => {
    if(!value.client.includes(ANY_KEYWORD)) {
      const priorities: any = await getPrioritySelection.mutateAsync(value);
      setAvailablePriorityList(priorities ? priorities : []);
    } else setAvailablePriorityList([]);
  };

  const onChangeServiceTypes = async (value?: object) => {
    const statuses: any = await getServiceTypeAvailableStatusesQuery.mutateAsync(value);
    const mappedStatuses = statuses?.map((item: any) => 
    { return { value: item.code, label: item.name };});
    setAvailableStatusList(mappedStatuses.concat(NEW_STATUS_DROPDOWN_ELEMENT));
  };

  const handleModalClose = () => {
    setOpenModel(false);
    setInteractionRuleListData({});
    setInteractionRuleConfigData(SAMPLE_INTERACTION_RULE_OBJECT);
    setPrioritySelectionObject(SAMPLE_PRIORITY_OBJECT);
    setAvailablePriorityList([]);
    setSelectedParentId('');
    setModels(ANY_ITEM_ARRAY);
    setSelectedParentIdForModel('');
    setQueryNodeType(NodeType.Client);
    handleClose();
  };

  const arrayValueValidator = (value: any) => {
    if (value?.filter((item: any )=> item !== '').length <= 0) {
      return true;
    } else {
      return false;
    }
  };

  const onSave = async () => {
    const validateName = isEmptyString(interactionRuleConfigData?.name) || !interactionRuleConfigData?.name;
    const validateServiceType = arrayValueValidator(interactionRuleConfigData?.serviceTypes) || !interactionRuleConfigData?.serviceTypes;
    const validateActionType = arrayValueValidator(interactionRuleConfigData?.actionTypes) || !interactionRuleConfigData?.actionTypes;
    const validateProcessType = arrayValueValidator(interactionRuleConfigData?.processTypes) || !interactionRuleConfigData?.processTypes;
    const validateClientContractBrand = !interactionRuleConfigData.nodeHierarchy;
    const validatePriority = arrayValueValidator(interactionRuleConfigData?.priorities) || !interactionRuleConfigData?.priorities;
    const validateRegion = arrayValueValidator(interactionRuleConfigData?.regions) || !interactionRuleConfigData?.regions;
    const validateModel = arrayValueValidator(interactionRuleConfigData?.models) || !interactionRuleConfigData?.models;
    const validateTriggerPointType = isEmptyString(interactionRuleConfigData?.triggerPoint) || !interactionRuleConfigData?.triggerPoint;
    let validateFromStatusChange = false;
    let validateToStatusChange = false;
    if (interactionRuleConfigData?.triggerPoint === InteractionRuleTriggerPointType.StatusChange) {
      validateFromStatusChange = arrayValueValidator(interactionRuleConfigData?.fromStatusType) || !interactionRuleConfigData?.fromStatusType;
      validateToStatusChange = arrayValueValidator(interactionRuleConfigData?.toStatusType) || !interactionRuleConfigData?.toStatusType;
    }
    let validateEventChange = false;
    if (interactionRuleConfigData?.triggerPoint === InteractionRuleTriggerPointType.SystemEvent) {
      validateEventChange = isEmptyString(interactionRuleConfigData?.eventType) || !interactionRuleConfigData?.eventType;
    }
    let validatePartActionType = false;
    if (interactionRuleConfigData?.eventType === InteractionRuleSystemEventType.PartActionExecution) {
      validatePartActionType = arrayValueValidator(interactionRuleConfigData?.partActionType) || !interactionRuleConfigData?.partActionType;
    }

    let validateTaskType = false;
    if (interactionRuleConfigData?.eventType === InteractionRuleSystemEventType.TaskExecution) {
      validateTaskType = arrayValueValidator(interactionRuleConfigData?.taskType) || !interactionRuleConfigData?.taskType;
    }
    const validateTextReferenceType = arrayValueValidator(interactionRuleConfigData?.textReferenceType) || !interactionRuleConfigData?.textReferenceType;
    const validateToTextMatch = isEmptyString(interactionRuleConfigData?.toTextMatch) || !interactionRuleConfigData?.toTextMatch;
    const validateTemplate = isEmptyString(interactionRuleConfigData?.templateId) || (interactionRuleConfigData?.templateId && interactionRuleConfigData.templateId < 0);
    const validateNote = isEmptyString(interactionRuleConfigData?.notes) || !interactionRuleConfigData?.notes;

    setInteractionRuleListData({ 
      ...interactionRuleListData, 
      isName: !!validateName,
      isClientContractBrand: !!validateClientContractBrand,
      isServiceType: !!validateServiceType,
      isActionType: !!validateActionType,
      isProcessType: !!validateProcessType,
      isPriority: !!validatePriority,
      isRegion: !!validateRegion,
      isModel: !!validateModel,
      isTriggerPointType: !!validateTriggerPointType,
      isFromStatus: !!validateFromStatusChange,
      isToStatus: !!validateToStatusChange,
      isEvent: !!validateEventChange,
      isPartActionType: !!validatePartActionType,
      isTaskType: !!validateTaskType,
      isTextReference: !!validateTextReferenceType,
      isToTextMatch: !!validateToTextMatch,
      isTemplate: !!validateTemplate,
      isNotes: !!validateNote,
    });

    if (validateName || validateClientContractBrand
      || validateServiceType || validateActionType
      || validateProcessType || validatePriority
      || validateRegion || validateModel
      || validateTriggerPointType || validateFromStatusChange
      || validateToStatusChange || validateEventChange
      || validatePartActionType || validateTaskType
      || validateTemplate) {
      return;
    }

    if (interactionRuleConfigData?.id === 0) {
      await createInteractionRuleQuery.mutateAsync(interactionRuleConfigData);
      setOpenToast(true);
      handleModalClose();
    } else {
      await updateInteractionRuleQuery.mutateAsync(interactionRuleConfigData);
      setOpenToast(true);
      handleModalClose();
    }
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    const nodeAttributeTypes = [NodeAttributeType.Models];
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
    setParentId(parentId);
    setNodeAttributeTypes(nodeAttributeTypes);
  };

  const handleTriggerPointSelect = (triggerPointType: string) => {
    if (triggerPointType === InteractionRuleTriggerPointType.StatusChange) {
      setInteractionRuleConfigData({
        ...interactionRuleConfigData,
        triggerPoint: triggerPointType,
        eventType: '',
        partActionType: [],
        taskType: [],
        partActionPartTypes: [],
        hasPartActionConditions: false,
        partActionConditions: []
      });
      setStatusChangeOpen(true);
      setSystemEventOpen(false);
      setTaskExecutionOpen(false);
      setPartActionExecutionOpen(false);
    } else if (triggerPointType === InteractionRuleTriggerPointType.SystemEvent) {
      setInteractionRuleConfigData({ ...interactionRuleConfigData, triggerPoint: triggerPointType, fromStatusType: [], toStatusType: [] });
      setSystemEventOpen(true);
      setStatusChangeOpen(false);
    }
  };

  const handleSystemEventSelect = (eventType: string) => {
    if (eventType === InteractionRuleSystemEventType.PartActionExecution) {
      setInteractionRuleConfigData({ ...interactionRuleConfigData, eventType: eventType, taskType: [] });
      setPartActionExecutionOpen(true);
      setTaskExecutionOpen(false);
    } else if (eventType === InteractionRuleSystemEventType.TaskExecution) {
      setInteractionRuleConfigData({
        ...interactionRuleConfigData,
        eventType: eventType,
        partActionType: [],
        partActionPartTypes: [],
        hasPartActionConditions: false,
        partActionConditions: []
      });
      setTaskExecutionOpen(true);
      setPartActionExecutionOpen(false);
    } else {
      setInteractionRuleConfigData({
        ...interactionRuleConfigData,
        eventType: eventType,
        taskType: [],
        partActionType: [],
        partActionPartTypes: [],
        hasPartActionConditions: false,
        partActionConditions: []
      });
      setPartActionExecutionOpen(false);
      setTaskExecutionOpen(false);
    }
  };

  const updateConditionData = (conditionId: number, fieldName: string, selectedValue: any) => {
    const conditionsArray = [...interactionRuleConfigData.partActionConditions];
    const updatedConditions = conditionsArray.map((condition) => 
      condition.id === conditionId ? {
        ...condition,
        [fieldName]: selectedValue
      } : condition
    );
    setInteractionRuleConfigData({ ...interactionRuleConfigData, partActionConditions: updatedConditions });
  };

  const onAddCondition = (isEnableCheckboxValue?: boolean) => {
    const newCondition = {
      id: (isEnableCheckboxValue || !interactionRuleConfigData.partActionConditions.length) ? 1 : Math.max(...interactionRuleConfigData.partActionConditions.map((condition) => condition.id)) + 1,
      joinOption: (isEnableCheckboxValue || !interactionRuleConfigData.partActionConditions.length) ? '' : 'OR',
      partActionType: '',
      operator: '',
      value: null
    };
    const updatedConditions = interactionRuleConfigData.partActionConditions?.length ? [...interactionRuleConfigData.partActionConditions] : [];
    updatedConditions.push(newCondition);
    updatedConditions.length > 0 && setInteractionRuleConfigData({
      ...interactionRuleConfigData,
      partActionConditions: isEnableCheckboxValue === false ? [] : updatedConditions,
      ...( isEnableCheckboxValue !== undefined && { hasPartActionConditions: isEnableCheckboxValue })
    });
  };

  const onRemoveCondition = (conditionData: InteractionRulePartActionConditionType) => {
    const updatedConditions = [...interactionRuleConfigData.partActionConditions].filter((condition) => condition.id !== conditionData.id);
    setInteractionRuleConfigData({ ...interactionRuleConfigData, partActionConditions: updatedConditions });
  };

  return (
    <>
      <Modal
        open={openModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContentWrapperBox>
          <CloseIcon handleClose={handleModalClose} />
          <HeadingWrapper variant={TypographyVariantProps.H6}>
            Interaction Rule #{`${interactionRuleConfigData?.id === 0 ? 'New' : interactionRuleConfigData?.id}`}
          </HeadingWrapper>
          <Grid container width="100%">
            <TextField
              required={true}
              label="Rule Name"
              fullWidth
              error={!!interactionRuleListData?.isName}
              value={interactionRuleConfigData?.name}
              onChange={(value) => {
                setInteractionRuleConfigData({ ...interactionRuleConfigData, name: value });
                setInteractionRuleListData({ ...interactionRuleListData, isName: isEmptyString(value) });
              }} 
            />
          </Grid>
          <Grid container width="100%" mt={2} gap={2}>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                id="service-type-options"
                label="Service Type *"
                required={true} error={!!interactionRuleListData?.isServiceType}
                selectedValue={interactionRuleConfigData?.serviceTypes}
                onChange={(serviceTypes) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, serviceTypes: convertToArray(serviceTypes) });
                  onChangeServiceTypes({ serviceTypes: convertToArray(serviceTypes) });
                  onChangePriorities({ ...prioritySelectionObject, serviceTypes: convertToArray(serviceTypes) });
                  setInteractionRuleListData({ ...interactionRuleListData, isServiceType: arrayValueValidator(convertToArray(serviceTypes)) });
                  if (convertToArray(serviceTypes).some((serviceType: string) => serviceType === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, serviceTypes: [ANY_KEYWORD] });
                  }
                  if (convertToArray(serviceTypes).length === 1 && !convertToArray(serviceTypes).some((serviceType: string) => serviceType === ANY_KEYWORD)) {
                    setServiceTypeCode(convertToArray(serviceTypes)[0]);
                  }
                }}
                items={setDropDownList(interactionRuleConfigData?.serviceTypes, serviceTypesWithAnyWildcard(serviceCommonTypes.serviceTypes))} 
              />
            </Grid>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                id="action-type-options"
                label="Action Type *"
                required={true}
                error={!!interactionRuleListData?.isActionType}
                selectedValue={interactionRuleConfigData?.actionTypes}
                onChange={(actionTypes) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, actionTypes: convertToArray(actionTypes) });
                  setInteractionRuleListData({ ...interactionRuleListData, isActionType: arrayValueValidator(convertToArray(actionTypes)) });
                  if (convertToArray(actionTypes).some((actionType: string) => actionType === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, actionTypes: [ANY_KEYWORD] });
                  }
                }}
                items={setDropDownList(interactionRuleConfigData?.actionTypes, actionTypesWithAnyWildcard(serviceCommonTypes.actionTypes))} 
              />
            </Grid>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                label="Process Type *"
                id="process-type-options"
                required={true}
                error={!!interactionRuleListData?.isProcessType}
                selectedValue={interactionRuleConfigData?.processTypes}
                onChange={(processTypes) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, processTypes: convertToArray(processTypes) });
                  setInteractionRuleListData({ ...interactionRuleListData, isProcessType: arrayValueValidator(convertToArray(processTypes)) });
                  if (convertToArray(processTypes).some((processType: string) => processType === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, processTypes: [ANY_KEYWORD] });
                  }
                }}
                items={setDropDownList(interactionRuleConfigData?.processTypes, processTypesWithAnyWildcard(serviceCommonTypes.processTypes))} 
              />
            </Grid>
          </Grid>
          <Grid container width="100%" mt={2}>
            <Grid xs>
              <SelectionButton
                label="Client Selection *"
                error={!!interactionRuleListData?.isClientContractBrand}
                value={interactionRuleConfigData?.nodeHierarchy || ''}
                onClick={() => {
                  setOpenParentSelector(true);
                  getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                }}
              />
            </Grid>
          </Grid>
          <Grid container width="100%" mt={2} gap={2}>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                label="Priority *"
                id="priority-select-options"
                required={true}
                error={!!interactionRuleListData?.isPriority}
                selectedValue={interactionRuleConfigData?.priorities}
                onChange={(priority) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, priorities: convertToArray(priority) });
                  setInteractionRuleListData({ ...interactionRuleListData, isPriority: arrayValueValidator(convertToArray(priority)) });
                  if (convertToArray(priority).some((priority: string) => priority === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, priorities: [ANY_KEYWORD] });
                  }
                }}
                items={setDropDownList(interactionRuleConfigData?.priorities, prioritiesWithAnyWildcard(availablePriorityList))}
              />
            </Grid>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                label="Region *"
                id="region-select"
                required={true}
                error={!!interactionRuleListData?.isRegion}
                selectedValue={interactionRuleConfigData?.regions}
                onChange={(regions) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, regions: convertToArray(regions) });
                  setInteractionRuleListData({ ...interactionRuleListData, isRegion: arrayValueValidator(convertToArray(regions)) });
                  if (convertToArray(regions).some((region: string) => region === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, regions: [ANY_KEYWORD] });
                  }
                }}
                items={setDropDownList(interactionRuleConfigData?.regions, regionTypeList)} 
              />
            </Grid>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                label="Model *"
                id="model-select"
                required={true}
                error={!!interactionRuleListData?.isModel}
                selectedValue={interactionRuleConfigData?.models}
                onChange={(models) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, models: convertToArray(models) });
                  setInteractionRuleListData({ ...interactionRuleListData, isModel: arrayValueValidator(convertToArray(models)) });
                  if (convertToArray(models).some((model: string) => model === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, models: [ANY_KEYWORD] });
                  }
                }}
                items={setDropDownList(interactionRuleConfigData?.models, models)} 
              />
            </Grid>
          </Grid>
          <Grid container width="100%" gap={2}>
            <Grid xs mt={2}>
              <SelectMenu
                key={interactionRuleConfigData?.eventType} // For refreshing dropdown items width when changed from part action execution
                id="trigger-point-select"
                label="Trigger Point *"
                required={true}
                validate={!!interactionRuleListData?.isTriggerPointType}
                selectedValue={interactionRuleConfigData?.triggerPoint}
                items={TRIGGER_POINT_MENU_OPTIONS}
                onChange={(value) => {
                  handleTriggerPointSelect(value);
                  setInteractionRuleListData({ ...interactionRuleListData, isTriggerPointType: isEmptyString(value) });
                }} />
            </Grid>
            {partActionExecutionOpen &&
              <>
                <Grid xs mt={2}>
                  <SelectMenu
                    id="event-select"
                    label="Event *"
                    required={true}
                    validate={!!interactionRuleListData?.isEvent}
                    selectedValue={interactionRuleConfigData?.eventType}
                    items={SYSTEM_EVENT_MENU_OPTIONS}
                    onChange={(value) => {
                      handleSystemEventSelect(value);
                      setInteractionRuleListData({ ...interactionRuleListData, isEvent: isEmptyString(value) });
                    } } />
                </Grid>
                <Grid xs mt={2}>
                  <MultiSelectCheckmarksMenu
                    id="part-action-execution"
                    label="Part Action Type *"
                    error={!!interactionRuleListData?.isPartActionType}
                    items={setDropDownList(interactionRuleConfigData?.partActionType || [], partActionTypes)}
                    selectedValue={interactionRuleConfigData?.partActionType}
                    onChange={(partActionType) => {
                      setInteractionRuleConfigData({ ...interactionRuleConfigData, partActionType: convertToArray(partActionType) });
                      setInteractionRuleListData({ ...interactionRuleListData, isPartActionType: arrayValueValidator(convertToArray(partActionType)) });
                      if (convertToArray(partActionType).some((partActionType: string) => partActionType === ANY_KEYWORD)) {
                        setInteractionRuleConfigData({ ...interactionRuleConfigData, partActionType: [ANY_KEYWORD] });
                      }
                    } } />
                </Grid>
              </>
            }
          </Grid>
          <Grid container width="100%" gap={2}>
            {statusChangeOpen &&
              <>
                <Grid xs mt={2}>
                  <MultiSelectCheckmarksMenu
                    id="from-status"
                    label="From Status *"
                    error={!!interactionRuleListData?.isFromStatus}
                    items={setDropDownList(interactionRuleConfigData?.fromStatusType || [], availableStatusesWithAnyWildcard(availableStatusList))}
                    selectedValue={interactionRuleConfigData?.fromStatusType}
                    onChange={(status) => {
                      setInteractionRuleConfigData({ ...interactionRuleConfigData, fromStatusType: convertToArray(status) });
                      setInteractionRuleListData({ ...interactionRuleListData, isFromStatus: arrayValueValidator(convertToArray(status)) });
                      if (convertToArray(status).some((status: string) => status === ANY_KEYWORD)) {
                        setInteractionRuleConfigData({ ...interactionRuleConfigData, fromStatusType: [ANY_KEYWORD] });
                      }
                    }} />
                </Grid>
                <Grid xs mt={2}>
                  <MultiSelectCheckmarksMenu
                    id="to-status"
                    label="To Status *"
                    error={!!interactionRuleListData?.isToStatus}
                    items={setDropDownList(interactionRuleConfigData?.toStatusType || [], availableStatusesWithAnyWildcard(availableStatusList))}
                    selectedValue={interactionRuleConfigData?.toStatusType}
                    onChange={(status) => {
                      setInteractionRuleConfigData({ ...interactionRuleConfigData, toStatusType: convertToArray(status) });
                      setInteractionRuleListData({ ...interactionRuleListData, isToStatus: arrayValueValidator(convertToArray(status)) });
                      if (convertToArray(status).some((statusType: string) => statusType === ANY_KEYWORD)) {
                        setInteractionRuleConfigData({ ...interactionRuleConfigData, toStatusType: [ANY_KEYWORD] });
                      }
                    }} />
                </Grid>
              </>
            }
            {systemEventOpen && !partActionExecutionOpen &&
              <Grid xs mt={2}>
                <SelectMenu
                  id="event-select"
                  label="Event *"
                  required={true}
                  validate={!!interactionRuleListData?.isEvent}
                  selectedValue={interactionRuleConfigData?.eventType}
                  items={SYSTEM_EVENT_MENU_OPTIONS}
                  onChange={(value) => {
                    handleSystemEventSelect(value);
                    setInteractionRuleListData({ ...interactionRuleListData, isEvent: isEmptyString(value) });
                  }} />
              </Grid>
            }
          </Grid>
          <Grid container width="100%" gap={2} alignItems="center">
            {partActionExecutionOpen &&
              <>
                <Grid xs mt={2}>
                  <MultiSelectCheckmarksMenu
                    id="part-action-part-type"
                    label="Part Type Category"
                    selectedValue={interactionRuleConfigData?.partActionPartTypes}
                    items={setDropDownList(interactionRuleConfigData?.partActionPartTypes || [], PART_TYPE_OPTIONS)}
                    onChange={(partTypes) => {
                      setInteractionRuleConfigData({
                        ...interactionRuleConfigData,
                        partActionPartTypes: convertToArray(partTypes).some((partType: string) => partType === ANY_KEYWORD) ? [ANY_KEYWORD] : convertToArray(partTypes)
                      });
                    } } />
                </Grid>
                <Grid xs mt={2}>
                  <CheckItem
                    label="Enable conditions"
                    value={interactionRuleConfigData?.hasPartActionConditions || false}
                    onChange={(value) => onAddCondition(value)}
                  />
                </Grid>
                {interactionRuleConfigData?.hasPartActionConditions &&
                  <Grid xs mt={2}>
                    <Button
                      variant={ButtonVariantProps.Secondary}
                      color={ButtonColorProps.Primary}
                      onClick={() => onAddCondition()}
                    >
                      + Add Condition
                    </Button>
                  </Grid>
                }
              </>
            }
            {taskExecutionOpen &&
              <Grid xs mt={2}>
                <MultiSelectCheckmarksMenu
                  id="task-execution"
                  label="Task Type *"
                  error={!!interactionRuleListData?.isTaskType}
                  items={setDropDownList(interactionRuleConfigData?.taskType || [], taskTypes)}
                  selectedValue={interactionRuleConfigData?.taskType}
                  onChange={(taskType) => {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, taskType: convertToArray(taskType) });
                    setInteractionRuleListData({ ...interactionRuleListData, isTaskType: arrayValueValidator(convertToArray(taskType)) });
                    if (convertToArray(taskType).some((taskType: string) => taskType === ANY_KEYWORD)) {
                      setInteractionRuleConfigData({ ...interactionRuleConfigData, taskType: [ANY_KEYWORD] });
                    }
                  }}
                />
              </Grid>
            }
          </Grid>
          {interactionRuleConfigData?.hasPartActionConditions && interactionRuleConfigData.partActionConditions.map((condition, index) =>
            <Grid key={condition.id} container alignItems="center" width="100%">
              <Grid mt={2}>
                <Button
                  variant={ButtonVariantProps.Icon}
                  onClick={() => onRemoveCondition(condition)}
                >
                  <CloseRounded fontSize="medium" color="error" />
                </Button>
              </Grid>
              {index !== 0 ?
                <Grid width="100px" mt={2} mr={1}>
                  <SelectMenu
                    id="condition-join-option-1"
                    label="Join Option"
                    items={PART_ACTION_CONDITION_JOIN_OPTIONS}
                    selectedValue={condition.joinOption}
                    onChange={(joinOption) => updateConditionData(condition.id, 'joinOption', joinOption )}
                  />
                </Grid> : <Grid width="100px" mt={2} mr={1}/>
              }
              <Grid width="170px" mt={2}>
                <SelectMenu
                  id="condition-part-action-type-1"
                  label="Part Action Type"
                  items={partActionTypes.filter((type) => type.value !== 'any')}
                  selectedValue={condition.partActionType}
                  onChange={(partActionType) => updateConditionData(condition.id, 'partActionType', partActionType )}
                />
              </Grid>
              <Grid width="130px" mt={2}>
                <SelectMenu
                  id="condition-operator-1"
                  label="Operator"
                  items={PART_ACTION_CONDITION_OPERATORS}
                  selectedValue={condition.operator}
                  onChange={(operator) => updateConditionData(condition.id, 'operator', operator )}
                />
              </Grid>
              <Grid xs mt={2}>
                <TextField
                  fullWidth
                  label="Value"
                  value={condition.value || ''}
                  inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(value) => updateConditionData(condition.id, 'value', value )}
                />
              </Grid>
            </Grid>
          )}
          <Grid container width="100%" mt={2} gap={2}>
            <Grid xs>
              <MultiSelectCheckmarksMenu
                id="text-match"
                label="Text Match"
                required={true}
                items={setDropDownList(interactionRuleConfigData?.textReferenceType || [], textReferenceTypes)}
                selectedValue={interactionRuleConfigData?.textReferenceType}
                onChange={(textReferenceType) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, textReferenceType: convertToArray(textReferenceType) });
                  setInteractionRuleListData({ ...interactionRuleListData, isTextReference: arrayValueValidator(convertToArray(textReferenceType)) });
                  if (convertToArray(textReferenceType).some((textReferenceType: string) => textReferenceType === ANY_KEYWORD)) {
                    setInteractionRuleConfigData({ ...interactionRuleConfigData, textReferenceType: [ANY_KEYWORD] });
                  }
                }}
              />
            </Grid>
            <Grid xs>
              <TextField
                fullWidth
                multiline
                label="To Text Match"
                value={interactionRuleConfigData?.toTextMatch}
                onChange={(value) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, toTextMatch: value });
                  setInteractionRuleListData({ ...interactionRuleListData, isToTextMatch: isEmptyString(value) });
                }} />
            </Grid>
          </Grid>
          <Grid container width="100%" mt={2}>
            <Grid xs>
              <SelectMenu
                id="template"
                label="Template *"
                required={true}
                validate={!!interactionRuleListData?.isTemplate}
                selectedValue={interactionRuleConfigData?.templateId}
                items={interactionTemplateList}
                onChange={(value) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, templateId: value });
                  setInteractionRuleListData({ ...interactionRuleListData, isTemplate: isEmptyString(value) });
                }}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>
          <Grid container width="100%" mt={2}>
            <Grid xs>
              <TextField
                label="Notes"
                fullWidth
                multiline
                rows={3}
                value={interactionRuleConfigData?.notes}
                onChange={(value) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, notes: value });
                  setInteractionRuleListData({ ...interactionRuleListData, isNotes: isEmptyString(value) });
                }}
              />
            </Grid>
          </Grid>
          <Grid container width="100%" mt={2}>
            <Grid>
              <Switch
                checked={interactionRuleConfigData?.isActive}
                color={ColorProps.Success}
                onChange={(event) => {
                  setInteractionRuleConfigData({ ...interactionRuleConfigData, isActive: event.target.checked });
                }}
              />
              isActive
            </Grid>
          </Grid>
          <Grid container mt={2} justifyContent="flex-end" gap={2}>
            <Grid>
              <Button variant={ButtonVariantProps.Secondary} onClick={handleModalClose}>Cancel</Button>
            </Grid>
            <Grid>
              <Button variant={ButtonVariantProps.Primary} onClick={onSave}>Save</Button>
            </Grid>
          </Grid>
          <MultiClientSelector
            open={openParentSelector}
            onChangeNodeType={onChangeNodeType}
            onClose={() => setOpenParentSelector(false)}
            handleParentSelection={(selectedClients: string[], selectedContracts: string[], clientHierarchy: string, selectedBrands?: string[]) => {
              setOpenParentSelector(false);

              if (selectedClients.length > 0) {
                setInteractionRuleConfigData({
                  ...interactionRuleConfigData,
                  client: selectedClients,
                  contract: selectedContracts.length > 0 ? selectedContracts : [ANY_KEYWORD],
                  brand: selectedBrands && selectedBrands.length > 0 ? selectedBrands : [ANY_KEYWORD],
                  nodeHierarchy: clientHierarchy,
                  priorities: []
                });
                onChangePriorities({
                  serviceTypes: prioritySelectionObject.serviceTypes,
                  client: selectedClients,
                  contract: selectedContracts.length > 0 ? selectedContracts : [ANY_KEYWORD],
                  brand: selectedBrands && selectedBrands.length > 0 ? selectedBrands : [ANY_KEYWORD],
                });
              } else {
                setInteractionRuleConfigData({
                  ...interactionRuleConfigData,
                  nodeHierarchy: clientHierarchy
                });
              }
            }}
            clients={clients}
            contracts={contracts}
            brands={brands}
            selectedMultiClients={interactionRuleConfigData?.client}
            selectedMultiContracts={interactionRuleConfigData?.contract}
            selectedMultiBrands={interactionRuleConfigData?.brand}
            hasBrandSelection={true}
            isMandotory={true}
          />
        </ContentWrapperBox>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message="Successfully Saved"
        onClose={() => setOpenToast(false)} 
      />
    </>
  );
};