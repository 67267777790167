import React from 'react';
import { FaultDefinitaionsProps } from '.';
import { ChildWrapper } from './FaultDefinitions.styles';
import { FaultDefinition } from '../../molecules/FaultDefinition';
import { Box } from '@mui/material';
import { Button, ButtonVariantProps, ButtonColorProps } from '../../atoms/Button';

export const FaultDefinitions: React.FC<FaultDefinitaionsProps> = ({
  faults,
  onChangeChildDefinitions,
  onChangeHeaderDefinition
}) => {
  return (
    <>
      {faults.map((fault, faultIndex) => (
        <div key={faultIndex}>
          <>
            <FaultDefinition key={faultIndex} showHeader={faultIndex === 0} isParentFaultDefinition={true} testDataId={`fault-${faultIndex}`} faultDefinitaionData={fault} onChange={(updatedData) => { onChangeHeaderDefinition(updatedData, faultIndex); }} />
            {fault.childFaults.map((childFault, index) => (
              <ChildWrapper key={index}>
                <FaultDefinition showHeader={false} faultDefinitaionData={childFault} testDataId={`fault-${faultIndex}-code-${index}`} onChange={(updatedData) => {     
                  const childFaults = fault.childFaults;
                  childFaults[index] = updatedData;
                  onChangeChildDefinitions(childFaults, faultIndex);
                }} />
              </ChildWrapper>
            ))}
            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Primary} onClick={() => onChangeChildDefinitions([...fault.childFaults, { name: '', customerId: '', customerId2: '', isActive: true }], faultIndex)} data-testid={`fault-${faultIndex}-add-fault-code`}>Add fault code</Button>
            </Box>
          </>
        </div>
      ))}
    </>
  );
};