import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariantProps, ButtonColorProps } from '../../../components/atoms/Button';
import {
  RoleTypography,
  RoleBox,
  PageActionTypography,
  ActionGrid,
} from './AddRole.styles';
import { containValidCharacters, nameValidation } from '../../../utils/roles';
import { PermissionTreeV2 } from '../../../components/molecules/PermissionTreeV2';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { useAddRole, useGetPermissions } from '../../../queries/roles-query';
import { AutocompleteTagType, Tags, getSelectedExistingTagsMapped, getNewTagsMapped } from '../../../components/organisms/Tags';
import { ActionPermissions, ColorProps, CustomPermission, Tag } from '../../../@types';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { EntityType } from '../../../configs/enums';
import { getFlatArray } from '../../../utils/array-utils';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { isDropDownEmpty, isEmptyString } from '../../../utils/common';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { VisibilityRuleDropDownItems } from '../../../@types/visibilityRule.type';
import { useGetVisibilityRules } from '../../../queries/visibilityRules-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { SelectMenu } from '../../../components/atoms/SelectMenu';
import { isUserHasPermission } from '../../../configs/permissions';
import { NAME_FIELD_HELPER_TEXT } from '../../../constants/common';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const AddRole: React.FC = () => {
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [visibilityRuleList, setVisibilityRuleList] = useState<VisibilityRuleDropDownItems[]>([]);
  const [visibilityRule, setVisibilityRule] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [nameHelperText, setNameHelperText] = useState('');
  const [addedPermissions, setAddedPermissions] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [validate, setValidate] = useState(false);

  const [checkAll] = useState<boolean>(false);
  const navigate = useNavigate();

  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [customPermissions, setCustomPermissions] = useState<CustomPermission[]>();

  const isError = isEmptyString(name) || containValidCharacters(name) || isDropDownEmpty(visibilityRule.toString());
  const addRoleQuery = useAddRole();
  const addTagsQuery = useAddTags();
  const roleTagsQuery = getAllTags(EntityType.TYPE_ROLE);
  const visibilityRulesQuery = useGetVisibilityRules();
  const { data } = useGetPermissions();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  TabHandler(`Create Role | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setCustomPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (visibilityRulesQuery.isSuccess) {
      const visibilityRulesItems: VisibilityRuleDropDownItems[] = [];
      visibilityRulesQuery?.data?.map((item) => {
        const visibilityRule = {
          value: item.id,
          label: item.name
        };
        visibilityRulesItems.push(visibilityRule);
      });
      setVisibilityRuleList(visibilityRulesItems);
    }
  }, [visibilityRulesQuery.isSuccess, visibilityRulesQuery.isLoading]);

  useEffect(() => {
    data && setPermissions(data.children);
  }, [data]);

  useEffect(() => {
    roleTagsQuery.data && setAllTags(roleTagsQuery.data);
  }, [roleTagsQuery.data]);

  const handleNameChange = (text: string) => {
    setNameHelperText(nameValidation(text, NAME_FIELD_HELPER_TEXT));
    setName(text);
  };

  const handleNoteChange = (text: string) => {
    setNote(text);
  };

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const onChangeVisibilityRule = (data: number) => {
    setVisibilityRule(data);
  };

  const handlePermissionChange = (checked: any[]) => {
    setAddedPermissions(checked);
  };

  const onChangeSelectedTags = (value: (string | AutocompleteTagType)[]) => {
    setSelectedTags(value);
  };

  const onChangeNewTags = (value: Tag[]) => {
    setNewTags(value);
  };

  const handleRoleCreate = async () => {
    setNameHelperText(nameValidation(name, NAME_FIELD_HELPER_TEXT));

    if (isError) {
      setValidate(true);
      return;
    }

    // call BFF POST endpoint in role api file for creating new role
    const timeout = (delay: number) => {
      return new Promise((res) => setTimeout(res, delay));
    };

    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    const rolePermissions = getFlatArray(addedPermissions)
      .filter(p => p.children.length === 0 && p.isGranted)
      .map(({ id, isGranted }) => ({ permissionId: id, isGranted }));

    const role: any = await addRoleQuery.mutateAsync({
      name: name,
      notes: note,
      isActive: isActive,
      visibilityRuleId: visibilityRule,
      rolePermissions
    });

    await addTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_ROLE,
      entityId: role.id,
      freshTags,
      existingTags
    }).then(async () => {
      setOpen(true);
      await timeout(1000);
      navigate(`/configure/users/roles/${role.id}`);
    });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  return (
    <PageContainer>
      <RoleTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        Create Role
      </RoleTypography>
      <RoleBox>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={!!nameHelperText}
              helperText={nameHelperText}
              label="Name"
              value={name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Tags
              selectedTags={selectedTags}
              newTags={newTags}
              setSeletedTags={onChangeSelectedTags}
              setNewTags={onChangeNewTags}
              allTags={allTags}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={4}
              value={note}
              onChange={handleNoteChange}
            />
          </Grid>
          {isUserHasPermission(ActionPermissions.Configure_User_Roles_Create_Vibility_Rules, customPermissions) && (
            <Grid item xs={12}>
              <SelectMenu
                required
                id="visibility-rule"
                labelId="visibility-rule"
                label="Visibility Rule"
                selectedValue={visibilityRule}
                onChange={(data) => onChangeVisibilityRule(data)}
                items={visibilityRuleList}
                validate={validate}
                optionalLabelEnabled={true}
              />
            </Grid>
          )}
          <Grid item ml="8px" xs={12}>
            <FormControlLabel
              control={<Switch color={ColorProps.Success} onChange={handleIsActiveChange} checked={isActive} defaultChecked />}
              label="Active"
            />
          </Grid>
          {isUserHasPermission(ActionPermissions.Configure_User_Roles_Create_Role_Permission, customPermissions) && (
            <Grid item container xs={12}>
              <ActionGrid>
                <PageActionTypography>
                  Page Action Permission{' '}
                </PageActionTypography>
                <PermissionTreeV2
                  checkAll={checkAll}
                  handlePermissionChange={handlePermissionChange}
                  permissions={permissions.length > 0 ? permissions : []}
                />
              </ActionGrid>
            </Grid>
          )}
          <Grid item>
            <Button variant={ButtonVariantProps.Primary} onClick={handleRoleCreate}>
              Create Role
            </Button>
          </Grid>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Role Added!"
            action={action}
          />
        </Grid>
      </RoleBox>
    </PageContainer>
  );
};
