import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const StyledTextfield = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
  }
`;