import React from 'react';
import { Stack, Grid } from '@mui/material';
import { CloseOutlined, DoneOutlineOutlined } from '@mui/icons-material';
import { DateTimePicker } from '../../atoms/DateTimePicker';
import { ButtonVariantProps } from '../../atoms/Button';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { EditDateTimePicker } from '../../molecules/EditDateTimePicker';
import { ServiceCheckInProps } from './ServiceCheckIn.props';
import {
  ContentPaper,
  TravelStartText,
  NoTravelText,
  NotesText,
  SubmitButton,
  StyledDivider
} from './ServiceCheckIn.styles';
import { LinkList } from '../../molecules/LinkList';
import { AllocatedServices } from '../../molecules/AllocatedServices';
import { FontSizeProps } from '../../../@types';

export const ServiceCheckIn: React.FC<ServiceCheckInProps> = ({
  travelStart,
  onSiteTime,
  noteText,
  interactionData,
  onChangeTravelStart,
  onChangeOnSiteTime,
  onNoteText,
  onSubmit,
  onClose,
  onChangeAllocation,
  allocatedServiceData,
  interactionsAvailable,
  allocatedServicesLoading
}) => {
  return (
    <ContentPaper mb={2}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {!travelStart && <NoTravelText>No Travel time detected, what time did you depart for this service ?</NoTravelText>}
          <Stack direction="row" alignItems="center" mb={2}>
            {travelStart ?
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant={TypographyVariantProps.Body2}>Travel Time Began:</Typography>
                <EditDateTimePicker
                  isEditable={true}
                  disableFuture={true}
                  value={travelStart}
                  textFontSize="0.875rem"
                  onChange={onChangeTravelStart}
                />
              </Stack>
              :
              <>
                <TravelStartText variant={TypographyVariantProps.Body2}>Travel Start:</TravelStartText>
                <DateTimePicker
                  label=""
                  disableFuture={true}  
                  value={travelStart}
                  onChange={onChangeTravelStart}
                />
              </>
            }
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant={TypographyVariantProps.Body2}>On Site Time:</Typography>
            <EditDateTimePicker
              isEditable={true}
              disableFuture={true}
              value={onSiteTime}
              textFontSize="0.875rem"
              onChange={onChangeOnSiteTime}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} width="100%">
          <NotesText
            label="Notes"
            fullWidth
            multiline
            rows={2}
            value={noteText}
            onChange={(text) => onNoteText(text)}
          />
        </Grid>
      </Grid>
      {interactionData.length !== 0 && (
        <>
          <StyledDivider />
          <LinkList data={interactionData} />
          <Typography>You are still checked-in at another Service. You can continue, however please consider completing your work on these Services first.</Typography>
        </>
      )}
      {allocatedServiceData.length !== 0 && (
        <>
          <StyledDivider/>
          <Typography>Other Services at this location:</Typography>
          <AllocatedServices onChange={onChangeAllocation} allocatedServiceData={allocatedServiceData} allocatedServicesLoading={allocatedServicesLoading} />
        </>
      )}
      {!interactionsAvailable &&
        <Stack direction="row-reverse">
          <SubmitButton
            variant={ButtonVariantProps.Secondary}
            onClick={onClose}
          >
            <CloseOutlined fontSize={FontSizeProps.Large} color="disabled" />
          </SubmitButton>
          <SubmitButton
            variant={ButtonVariantProps.Secondary}
            onClick={() => {
              onSubmit(true);
              onClose();
            }}
          >
            <DoneOutlineOutlined fontSize={FontSizeProps.Large} color="success" />
          </SubmitButton>
        </Stack>
      }
    </ContentPaper>
  );
};