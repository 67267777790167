import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';
import { Checkbox } from '@mui/material';

export const GridContainer = styled('div')((props: { isMobile?: boolean; rowLength: number }) =>`
    height: ${props.rowLength === 1 ? '200px' : props.rowLength === 2 ? '300px' : props.rowLength > 2 ? '350px' : '100px'};
    width: 100%;
    margin-top: ${props.isMobile && '10px'};
    .MuiDataGrid-main {
      margin-top: ${props.isMobile && '-30px'};
      border: ${props.isMobile && 'none !important'};
    }
    /* Allows the MUI grid rows to expand to child height */
    .MuiDataGrid-viewport,
    .MuiDataGrid-row,
    .MuiDataGrid-renderingZone {
      max-height: fit-content !important;
      border: ${props.isMobile && 'none !important'};
    }
    /* Allows the MUI grid rows to expand to child height */
    .MuiDataGrid-cell {
      overflow: auto;
      white-space: initial !important;
      line-height: 16px !important;
      display: flex !important;
      align-items: center;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      border: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-columnHeaderTitle {
      display: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-columnHeaders {
      visibility: ${props.isMobile && 'hidden !important'};
    }
    .MuiDataGrid-toolbarContainer {
      visibility: hidden !important;
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-root {
      border: ${props.isMobile && 'none !important'};
    }
  };`
);

export const SmallLabel = styled(Typography)`
  padding: 5px;
  font-size: small;
`;

export const SmallMobileLabel = styled(Typography)`
  padding: 1px;
  font-style: italic;
  font-size: x-small;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 1px;
  margin-left: -2px;
  margin-top: -5px;
`;