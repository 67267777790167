import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from '@mui/material';
import moment from 'moment';
import { CustomDatePickerFilterProps } from './CustomDatePickerFilter.props';
import { StyledBox, StyledTextfield } from './CustomDatePickerFilter.styles';

export const CustomDatePickerFilter: React.FC<CustomDatePickerFilterProps> = ({
  item,
  applyValue
}) => {
  const handleDateChange = (dateValue: any) => {
    applyValue({ ...item, value: dateValue ? moment(new Date(dateValue)).format('YYYY-MM-DD') : null });
  };

  const handleKeyboardInput = (dateValue: any, keyboardInputValue?: string) => {
    keyboardInputValue && applyValue({ ...item, value: keyboardInputValue });
  };

  const handleSetToday = () => {
    applyValue({ ...item, value: moment().format('YYYY-MM-DD') });
  };

  const handleClear = () => {
    applyValue({ ...item, value: null });
  };

  const CustomActionBar = () => (
    <StyledBox>
      <Button onClick={handleClear} size="small" color="primary">
        Clear
      </Button>
      <Button onClick={handleSetToday} size="small" color="primary">
        Today
      </Button>
    </StyledBox>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        inputFormat="YYYY-MM-DD"
        views={['year', 'month', 'day']}
        ignoreInvalidInputs
        showDaysOutsideCurrentMonth
        value={item.value ? moment(item.value) : null}
        onChange={handleKeyboardInput}
        onAccept={handleDateChange}
        renderInput={(params) => (
          <StyledTextfield
            {...params}
          />
        )}
        components={{
          ActionBar: () => (
            <CustomActionBar/>
          ),
        }}
      />
    </LocalizationProvider>
  );
};