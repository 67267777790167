import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Autocomplete, Grid } from '@mui/material';
import MaterialTextField from '@mui/material/TextField';
import { PageContainer, HorizontalAlignProps } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Box } from '../../../components/atoms/Box';
import { TextField } from '../../../components/atoms/TextField';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../components/atoms/FormControlLabel';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { WrapperBox, ContentWrapper, StructureFieldsWrapper } from './AddEditStructureType.styles';
import { StructureType, KeyValueType, StructureTypeBasicData, StructureTypeEnum } from './AddEditStructureType.props';
import { StructureTypeFields as preDefinedStructureTypeFields } from '../../../constants/structureType';
import { Switch } from '../../../components/atoms/Switch';
import {
  useGetStructureTypesByCategoryCode,
  useCreateStructureType,
  useUpdateStructureType,
  useGetStructureTypesExistanceByCode,
  useGetStructureTypesByCodeForUpdate
} from '../../../queries/structure-type-query';
import { ActionPermissions, ChildNodeType, CustomPermission, InputCategoryProps, PropertyType, StructureTypeHelper } from '../../../@types';
import { StructureTypeCategory } from '../../../configs/enums';
import { ColorProps } from '../../../@types';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { NotFoundError } from '../../Error/NotFound';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { CODE_NOT_EXIST_HELPER_TEXT, SAMPLE_STRUCTURE_TYPE, SAMPLE_STRUCTURE_TYPE_HELPER } from '../../../constants/structureType';
import { CODE_FIELD_HELPER_TEXT, NAME_FIELD_HELPER_TEXT } from '../../../constants/common';
import { getActiveStructureTypes, getSelectedChildNodeTypes, getStructureTypesArray, isError, nameValidation } from '../../../utils/structure-type';
import { RoutePath } from '../../../@types/route.type';
import { getSelectedProperties } from '../../../utils/structure-type';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const AddEditStructureType: React.FC = () => {
  const { id }: any = useParams();
  const [searchParams] = useSearchParams();
  let isNew = searchParams.get('isNew') === 'true' && id === 'create';
  const navigate = useNavigate();

  const [structureTypeBasicData, setStructureTypeBasicData] = useState<StructureTypeBasicData>(SAMPLE_STRUCTURE_TYPE);
  const [helperText, setHelperText] = useState<StructureTypeHelper>(SAMPLE_STRUCTURE_TYPE_HELPER);

  const [openToast, setOpenToast] = useState(false);
  const [structureTypeFields, setStructureTypeFields] = useState<StructureType[]>(() => {
    return getStructureTypesArray(preDefinedStructureTypeFields);
  });
  const [preferedChildren, setPreferedChildren] = useState<KeyValueType[]>([]);
  const [structureTypes, setStructureTypes] = useState<KeyValueType[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);

  const getStructureTypesByCategoryCode = useGetStructureTypesByCategoryCode(StructureTypeCategory.Operational);
  const createStructureType = useCreateStructureType();
  const updateStructureType = useUpdateStructureType();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getStructureTypeQuery = useGetStructureTypesByCodeForUpdate(id);
  const getStructureTypesExistanceByCode = useGetStructureTypesExistanceByCode(structureTypeBasicData.code);

  TabHandler(isNew ? `Create Structure Type | ${PLATFORM_NAME}` : `Structure Type: ${id} | Structure Types | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    isNew || getStructureTypeQuery.refetch();
  }, [isNew]);

  useEffect(() => {
    if (!isNew && getStructureTypeQuery.data) {
      setStructureTypeBasicData({ ...getStructureTypeQuery.data });

      const { propertyTypes, childNodeTypes } = getStructureTypeQuery.data;
      
      propertyTypes && propertyTypes.map((property: PropertyType) => {
        const field = structureTypeFields.find(field => field.key === property.propertyType);

        if (field) field.selected = true;
      });

      childNodeTypes && childNodeTypes.map((childNode: ChildNodeType) => {
        const isChildExist = preferedChildren.find((child) => child?.id === childNode?.code);
        if (!isChildExist) {
          setPreferedChildren(preValue => ([...preValue, { id: childNode.code, label: childNode.name }]));
        }
      });
    }
  }, [getStructureTypeQuery.data, isNew]);
  

  useEffect(() => {
    if (getStructureTypesByCategoryCode.data) {
      const filteredStructureTypes = isNew ? getStructureTypesByCategoryCode.data : getStructureTypesByCategoryCode.data.filter(type => type.code !== id);
      setStructureTypes(
        getActiveStructureTypes(filteredStructureTypes, preferedChildren)
      );
    }
  }, [getStructureTypesByCategoryCode.data, preferedChildren, isNew]);

  // check code already exist or not
  useEffect(() => {
    if(getStructureTypesExistanceByCode.data) {
      setStructureTypeBasicData({ ...structureTypeBasicData, isCodeExist: true });
      setHelperText({ ...helperText, codeHelperText: CODE_NOT_EXIST_HELPER_TEXT });
    } else if(getStructureTypesExistanceByCode.error) {
      setStructureTypeBasicData({ ...structureTypeBasicData, isCodeExist: false });
    }
  }, [getStructureTypesExistanceByCode.data, getStructureTypesExistanceByCode.error]);

  const handleNameChange = (value: string) => {
    setStructureTypeBasicData(prevValue => ({ ...prevValue, name: value }));
    setHelperText({ ...helperText, nameHelperText: nameValidation(value, InputCategoryProps.Name, NAME_FIELD_HELPER_TEXT) });
  };

  const handleCodeChange = (value: string) => {
    setStructureTypeBasicData(prevValue => ({ ...prevValue, code: value }));
    value && setHelperText({ ...helperText, codeHelperText: nameValidation(value, InputCategoryProps.Code, CODE_NOT_EXIST_HELPER_TEXT) });
  };

  const onChangeStructureTypeField = (key: string, selected: boolean) => {
    setStructureTypeFields(prevValue => {
      const field = prevValue.find(value => value.key === key);

      if (field) {
        field.selected = selected;
      }

      return [...prevValue];
    });
  };

  const handleCodeExist = async (value: string) => {
    value && await getStructureTypesExistanceByCode.refetch();
  };

  const onSaveChanges = async () => {
    const validateName = nameValidation(structureTypeBasicData.name, InputCategoryProps.Name, NAME_FIELD_HELPER_TEXT);
    const validateCode = nameValidation(structureTypeBasicData.code, InputCategoryProps.Code, CODE_FIELD_HELPER_TEXT);

    setHelperText({ 
      ...helperText,
      nameHelperText: validateName,
      codeHelperText: structureTypeBasicData.isCodeExist ? CODE_NOT_EXIST_HELPER_TEXT : validateCode
    });

    if (isError(structureTypeBasicData.isCodeExist, validateName, validateCode)) {
      return;
    }

    if (isNew) {
      const structureTypeResponse: any = await createStructureType.mutateAsync({
        ...structureTypeBasicData,
        categoryCode: StructureTypeCategory.Operational,
        propertyTypes: getSelectedProperties(structureTypeFields),
        childNodeTypes: getSelectedChildNodeTypes(preferedChildren)
      });

      setOpenToast(true);
      navigate(`${RoutePath.UpdateStructureType}/${structureTypeResponse.code}`);
    } else {
      await updateStructureType.mutateAsync({
        ...structureTypeBasicData,
        propertyTypes: getSelectedProperties(structureTypeFields),
        childNodeTypes: getSelectedChildNodeTypes(preferedChildren)
      });

      getStructureTypeQuery.refetch();
      setOpenToast(true);
    }
  };

  if (isNew && isUserHasPermission(ActionPermissions.Configure_Structure_Type_Create, permissions)) {
    isNew = true;
  } else if (isUserHasPermission(ActionPermissions.Configure_Structure_Type_Edit, permissions)) {
    isNew = false;
  }

  if (!getStructureTypeQuery.data && !isNew) {
    return getStructureTypeQuery.isLoading ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  } else if (isNew && !isUserHasPermission(ActionPermissions.Configure_Structure_Type_Create, permissions)) {
    return getStructureTypeQuery.isLoading ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer align={HorizontalAlignProps.Center}>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        {isNew ? StructureTypeEnum.AddStructureType : StructureTypeEnum.EditStructureType}
      </Typography>
      <WrapperBox>
        <ContentWrapper>
          <TextField
            fullWidth
            required
            error={!!helperText.nameHelperText}
            helperText={helperText.nameHelperText}
            label="Name"
            value={structureTypeBasicData.name}
            onChange={handleNameChange}
          />
        </ContentWrapper>
        <ContentWrapper>
          <TextField
            fullWidth
            required
            disabled={!isNew}
            error={!!helperText.codeHelperText}
            helperText={helperText.codeHelperText}
            label="Code"
            value={structureTypeBasicData.code}
            onChange={handleCodeChange}
            onBlur={(value) => handleCodeExist(value)}
          />
        </ContentWrapper>
        <StructureFieldsWrapper>
          {structureTypeFields.map((item, index) => (
            <Box key={index}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={item.selected}
                    onChange={() => { onChangeStructureTypeField(item.key, !item.selected); }}
                    color={CheckboxColorProps.Success}
                  />
                }
                label={item.value}
                labelPlacement={FormControlLabelPlacementProps.End}
              />
            </Box>
          ))}
        </StructureFieldsWrapper>
        <ContentWrapper>
          <Autocomplete
            multiple
            size="small"
            id="multiple-limit-types"
            options={structureTypes}
            getOptionLabel={(option) => option?.label}
            value={preferedChildren}
            renderInput={(params) => (
              <MaterialTextField {...params} label="Prefered Children" />
            )}
            onChange={(event, value) => {
              setPreferedChildren(value);
            }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <FormControlLabel
            control={
              <Switch
                color={ColorProps.Success}
                onChange={event => setStructureTypeBasicData(prevValue => ({ ...prevValue, isActive: event.target.checked }))}
                checked={structureTypeBasicData.isActive}
              />
            }
            label="Active"
          />
        </ContentWrapper>
        <ContentWrapper>
          <Button variant={ButtonVariantProps.Primary} onClick={onSaveChanges}>{isNew ? 'Save' : 'Update'}</Button>
        </ContentWrapper>
      </WrapperBox>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message="Successfully saved"
        onClose={() => { setOpenToast(false); }}
      />
    </PageContainer>
  );
};