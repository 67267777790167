import React, { useEffect, useRef, useState } from 'react';
import { ServicePartAccordionFragmentProps } from './ServicePartAccordionFragment.props';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Grid, Link, List, ListItemButton, ListItemText, Snackbar, Stack } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { SAMPLE_PART, SAMPLE_PART_LOCATION, SAMPLE_SFH } from '../../../../constants/part';
import { PartChildrenObject, PartConditionDropdown, PartHistory, PartLocationObject, PartObject, SfhObject } from '../../../../@types/part.type';
import { useGetLatestPartMomentHistory, useGetOnePartForService, useGetPartConditionsForService, useGetPartLocationDataByIdForService, useGetPartRepairHistory, useUpdatePartForService } from '../../../../queries/part-query';
import { PartTypeActionSHFData } from '../../../../components/molecules/PartTypeActionSHFData';
import { UnderlineTypeProps } from '../../../../components/atoms/Link';
import { ActionPermissions, CustomPermission, FaultCode, PartType, SizeProps, Tag } from '../../../../@types';
import { useGetOnePartTypeForService } from '../../../../queries/part-type-query';
import { ChipStyled } from '../../../../components/organisms/Tags/Tags.styles';
import { Folders } from '../../../../@types/uploaded-files.type';
import { useGetUploadedImages } from '../../../../queries/uploadedfiles-query';
import { ProfilePicProps } from '../../../../components/organisms/MultipleImageUpload';
import { ClampedTypography, ImageBox, StyledSelectMenu, StyledTableWrapper } from './ServicePartAccordionFragment.styles';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags, Tags } from '../../../../components/organisms/Tags';
import { EntityType } from '../../../../configs/enums';
import { getAllTags, useAddTags } from '../../../../queries/tags-query';
import { getFleetTagDropDownSelectedLabel, getSFHDropDownSelectedLabel } from '../../../../utils/part';
import { PartStructure } from '../../../../components/molecules/PartStructure';
import { getPartById } from '../../../../api/part';
import { formatDateTime, formatShortDate } from '../../../../utils/common';
import { useGetLatestShipmentByPartId } from '../../../../queries/shipment-query';
import { ShipmentByPartData } from '../../../../@types/shipment.type';
import { useGetNodeAttributeById } from '../../../../queries/structure-query';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { Modal } from '../../../../components/atoms/Modal';
import { StyledImage } from '../../../../components/organisms/MultipleImageUpload/MultipleImageUpload.styles';
import { SAMPLE_PART_TYPE } from '../../../../constants/partType';

export const ServicePartAccordionFragment: React.FC<ServicePartAccordionFragmentProps> = ({ service, partId, expand }) => {
  const [conditions, setConditions] = useState<PartConditionDropdown[]>([]);
  const [part, setPart] = useState<PartObject>(SAMPLE_PART);
  const [partType, setPartType] = useState<PartType>(SAMPLE_PART_TYPE);
  const [partLocation, setPartLocation] = useState<PartLocationObject>(SAMPLE_PART_LOCATION);
  const [sfhDetails, setSfhDetails] = useState<SfhObject>(SAMPLE_SFH);
  const [partPermissions, setPartPermissions] = useState<CustomPermission[]>();
  const [multipleImages, setMultipleImages] = useState<ProfilePicProps[]>([]);
  const [fullImage, setFullImage] = useState('');
  const [open, setOpen] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [children, setChildren] = useState<PartChildrenObject[]>();
  const [movementHistory, setMovementHistory] = useState<PartHistory>();
  const [repairHistory, setRepairHistory] = useState<PartHistory[]>();
  const [observedFaultCode, setObservedFaultCode] = useState<FaultCode>();
  const [latestShipment, setLatestShipment] = useState<ShipmentByPartData>();
  const [saveTags, setSaveTags] = useState<boolean>(false);

  const isFirstRender = useRef(true);
  const observedFaultAndDescId = service.attributes.find((attribute) => attribute.code === 'obf')?.value?.value?.id || -1;

  const getOnePartForService = useGetOnePartForService(partId);
  const getPartConditionsForService = useGetPartConditionsForService();
  const getOnePartTypeForService = useGetOnePartTypeForService(part.partType.id);
  const getPartLocationDataByIdForService = useGetPartLocationDataByIdForService(part.id);
  const uploadedImagesQuery = useGetUploadedImages(partType.id, Folders.PartTypesImages);
  const updatePartForService = useUpdatePartForService(partId);
  const updateTagsQuery = useAddTags();
  const userTagsQuery = getAllTags(EntityType.TYPE_SERIALIESEDPARTS);
  const getLatestPartMomentHistory = useGetLatestPartMomentHistory(partId);
  const getPartRepairHistory = useGetPartRepairHistory(partId);
  const getLatestShipmentByPartId = useGetLatestShipmentByPartId(partId);
  const getObservedFaultCode = useGetNodeAttributeById(observedFaultAndDescId);
  const getUserSystemPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getOnePartForService.data) {
      setPart(getOnePartForService.data);
      setSelectedTags(mapSavedTags(getOnePartForService.data?.tags || []));
    }
  }, [getOnePartForService.data]);

  useEffect(() => {
    getOnePartTypeForService.data && setPartType(getOnePartTypeForService.data);
  }, [getOnePartTypeForService.data]);

  useEffect(() => {
    getObservedFaultCode.data && setObservedFaultCode(getObservedFaultCode.data);
  }, [getObservedFaultCode.data]);

  useEffect(() => {
    getLatestShipmentByPartId.data && setLatestShipment(getLatestShipmentByPartId.data);
  }, [getLatestShipmentByPartId.data]);

  useEffect(() => {
    getPartLocationDataByIdForService.data && setPartLocation(getPartLocationDataByIdForService.data);
  }, [getPartLocationDataByIdForService.data]);

  useEffect(() => {
    getPartConditionsForService.data && setConditions(getPartConditionsForService.data);
  }, [getPartConditionsForService.data]);

  useEffect(() => {
    getLatestPartMomentHistory.data && setMovementHistory(getLatestPartMomentHistory.data);
  }, [getLatestPartMomentHistory.data]);

  useEffect(() => {
    getPartRepairHistory.data && setRepairHistory(getPartRepairHistory.data);
  }, [getPartRepairHistory.data]);

  useEffect(() => {
    if (uploadedImagesQuery.data) {
      const images: ProfilePicProps[] = [];
      const retrievedImages: any = uploadedImagesQuery.data.sort((a, b) => a?.id - b?.id);
      retrievedImages.map((retrievedImage: any) => {
        images.push({
          url: retrievedImage?.url,
          id: retrievedImage?.id,
        });
      });
      setMultipleImages([...retrievedImages]);
    }
  }, [uploadedImagesQuery.data]);

  useEffect(() => {
    getUserSystemPermissionsQuery.data && setPartPermissions(getUserSystemPermissionsQuery.data);
  }, [getUserSystemPermissionsQuery.data]);

  const onOpenPicture = async (image: any) => {
    if (image) {
      setFullImage(image);
      setOpen(true);
    }
  };

  useEffect(() => {
    uploadedImagesQuery.refetch();
  }, [partType]);

  const handleTagBoxClose = () => setOpen(false);

  useEffect(() => {
    userTagsQuery.data && setAllTags(userTagsQuery.data);
  }, [userTagsQuery.data]);

  useEffect(() => {
    if (partType) {
      const hardwares = partType.hardwares?.map(hardware => ({
        value: hardware?.id?.toString() || '',
        label: hardware?.version || ''
      }));
      const softwares = partType.softwares?.map(software => ({
        value: software?.id?.toString() || '',
        label: software?.version || ''
      }));
      const firmwares = partType.firmwares?.map(firmware => ({
        value: firmware?.id?.toString() || '',
        label: firmware?.version || ''
      }));
      setSfhDetails({ hardwares, softwares, firmwares });
    }
  }, [partType]);

  useEffect(() => {
    const fetchChildren = async () => {
      const promises = part.partChildrens?.map(async (child) => {
        try {
          const childPart = await getPartById(child.id);
          return { ...child, hasChildren: childPart && childPart.partChildrens && childPart.partChildrens.length > 0 };
        } catch (error) {
          return { ...child, hasChildren: false };
        }
      });

      if (promises) {
        const children = await Promise.all(promises);
        setChildren(children);
      }
    };

    fetchChildren();
  }, [part]);

  const handlePartChange = async (val: PartObject) => {
    await updatePartForService.mutateAsync({ ...val, serviceId: service.id });
    setPart({ ...val });
    setShowSavedMessage(true);
  };

  const handleTagChanges = async () => {
    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    await updateTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_SERIALIESEDPARTS,
      entityId: partId,
      freshTags,
      existingTags
    });
    setShowSavedMessage(true);

  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      handleTagChanges();
    }
  }, [saveTags]);

  const onSelectedTagChange = (selected: (AutocompleteTagType | string)[]) => {
    setSelectedTags(selected);
    setSaveTags(!saveTags);
  };

  const onNewTagsChange = (tags: Tag[]) => {
    setNewTags(tags);
    setSaveTags(!saveTags);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSavedMessage(false);
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  const RepairHistoryComponent: React.FC<{ partHistory: PartHistory }> = ({ partHistory }) => {
    return (
      <ListItemButton key={partHistory.id} href={`/parts/${partHistory.partId} `}>
        <ListItemText><Typography>{formatShortDate(partHistory.createdAt)}:</Typography></ListItemText>
        <ListItemText><Link href={`/services/${partHistory.serviceId}`}><Typography>{partHistory.serviceId}</Typography></Link></ListItemText>
      </ListItemButton>
    );
  };

  return (
    <Accordian defaultExpanded={expand} title={
      <Grid container display="flex" justifyContent="space-between">
        <Grid item spacing={0.5} container display="flex">
          <Grid item>
            <Typography>Parts | </Typography>
          </Grid>
          <Grid item>
            <Typography>{part.partType.name} :</Typography>
          </Grid>
          <Grid item display="flex">
            <Typography fontWeight={TypographyFontWeightProps.Bold}>{part.serial1}</Typography>
          </Grid>
          <Grid item flex="1"></Grid>
          <Grid item>
            <PartTypeActionSHFData hw={part.hardwareRevision || ''} sw={part.softwareVersion || ''} fw={part.firmwareVersion || ''} />
          </Grid>
        </Grid>
      </Grid>
    }>
      <Grid container display="flex" direction="row" spacing={0.5}>
        {/* part  type data grid*/}
        <Grid item container direction="column" spacing={1.0} xs={12} sm={6} md={4} mt={.5}>
          <Grid item container>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.H6}>Part Type</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} sm={3}>
              {isUserHasPermission(ActionPermissions.Part_Change_Part_Type, partPermissions) ?
                <Link href={`/configure/partTypes/${part.partType.id} `}>{part.partType.name}</Link>
                : <Typography variant={TypographyVariantProps.Subtitle2}>{part.partType.name}</Typography>
              }
              
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">P/N: {part.partType.number}</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} sm={4}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Client/Contract</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {partLocation.nodeList[0] &&
                  <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                    <Link href={`/configure/clients/${partLocation.nodeList[0].nodeTypeCode}/${partLocation.nodeList[0].id}`}>{partLocation.nodeList[0].name}</Link >
                  </Typography >
                }
                {
                  partLocation.nodeList[1] &&
                  <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                    &nbsp;/&nbsp;
                    <Link href={`/configure/clients/${partLocation.nodeList[1].nodeTypeCode}/${partLocation.nodeList[1].id}`}>{partLocation.nodeList[1].name}</Link>
                  </Typography>
                }
              </Stack >
            </Grid >
          </Grid >
          <Grid item container spacing={0.5}>
            <Grid item xs={12} sm={3}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Tags</Typography>
            </Grid>
            <Grid item xs={12} sm={9} flex={1} spacing={1}>
              {partType.tags &&
                partType.tags.map((tag) => (
                  <ChipStyled
                    key={tag.id}
                    bgColor={tag.colour}
                    size={SizeProps.Small}
                    isSystem={tag.isSystem}
                    variant="outlined"
                    label={tag.text}
                    sx={{ marginLeft: 1 }}
                  />
                ))
              }
            </Grid>
          </Grid>
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Notes</Typography>
            </Grid>
            <Grid item xs={12}>
              {partType.notes && <ClampedTypography variant={TypographyVariantProps.Body1}>{partType.notes}</ClampedTypography>}
            </Grid>
          </Grid>
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Photos</Typography>
            </Grid>
            <Grid item xs={12} spacing={0.5}>
              {multipleImages && multipleImages.map((image) => <ImageBox key={image.id} src={image.url} onClick={() => onOpenPicture(image.url)} />)}
            </Grid>
          </Grid>
        </Grid >
        {/* part  data grid*/}
        < Grid item container direction="column" spacing={1.0} xs={12} sm={6} md={4} mt={.5} >
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.H6}>Part Details</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} sm={3}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Serial&nbsp;1</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {isUserHasPermission(ActionPermissions.Part, partPermissions) ?
                <Typography variant={TypographyVariantProps.Subtitle2}> <Link href={`/parts/${partId}`}>{part.serial1}</Link></Typography>
                : <Typography variant={TypographyVariantProps.Subtitle2}>{part.serial1}</Typography>
              }
            </Grid>
          </Grid>
          <Grid item container spacing={0.5}>
            <Grid item xs={12} sm={3}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Serial&nbsp;2</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant={TypographyVariantProps.Subtitle2}>{part.serial2}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={0} >
            <Grid item xs={12} sm={3}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Serial&nbsp;3</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant={TypographyVariantProps.Subtitle2}>{part.serial3}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                <Typography variant={TypographyVariantProps.Subtitle2}>Location </Typography>
                {partLocation.nodeList[0] &&
                  <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                    <Link href={`/configure/clients/${partLocation.nodeList[0].nodeTypeCode}/${partLocation.nodeList[0].id}`}>{partLocation.nodeList[0].name}</Link>
                  </Typography>
                }
                {partLocation.nodeList[1] &&
                  <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                    &nbsp;/&nbsp;
                    <Link href={`/configure/clients/${partLocation.nodeList[1].nodeTypeCode}/${partLocation.nodeList[1].id}`}>{partLocation.nodeList[1].name}</Link>
                  </Typography>
                }
                {partLocation.nodeList[2] &&
                  <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                    &nbsp;/&nbsp;
                    <Link href={`/configure/clients/${partLocation.nodeList[2].nodeTypeCode}/${partLocation.nodeList[2].id}`}>{partLocation.nodeList[2].name}</Link>
                  </Typography>
                }
                <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                  &nbsp;/&nbsp;{partLocation.name}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item container spacing={0.5} >
            <Grid item xs={12} flex={1}>
              <Tags
                selectedTags={selectedTags}
                newTags={newTags}
                setSeletedTags={onSelectedTagChange}
                setNewTags={onNewTagsChange}
                allTags={allTags}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={0.5} >
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Notes</Typography>
            </Grid>
            <Grid item xs={12}>
              {part.notes && <ClampedTypography variant={TypographyVariantProps.Body1}>{part.notes}</ClampedTypography>}
            </Grid>
          </Grid>
        </Grid >
        {/* part  parameters grid*/}
        < Grid item container direction="column" spacing={1.0} xs={12} sm={6} md={4} mt={.5} >
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.H6}>Part Parameters</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <StyledSelectMenu
                disabled={!isUserHasPermission(ActionPermissions.Part_Condition, partPermissions)}
                required={false}
                size={SizeProps.Small}
                id="demo-simple-select-required"
                labelId="demo-simple-select-required-label"
                label="Condition *"
                selectedValue={part.conditionCode}
                onChange={(val) => handlePartChange({ ...part, conditionCode: val })}
                items={conditions}
              />
            </Grid>
          </Grid>
          {
            sfhDetails?.softwares && sfhDetails?.softwares.length > 0 && (
              <Grid item container justifyContent={'flex-start'} spacing={0.5}>
                <Grid item xs={12}>
                  <StyledSelectMenu
                    required={false}
                    size={SizeProps.Small}
                    id="demo-simple-select-required"
                    labelId="demo-simple-select-required-label"
                    label="Software"
                    selectedValue={part.softwareVersionId || '-1'}
                    onChange={(softwareVersionId) => handlePartChange({ ...part, softwareVersionId, softwareVersion: getSFHDropDownSelectedLabel(softwareVersionId, sfhDetails.softwares) })}
                    items={sfhDetails.softwares}
                    optionalLabelEnabled={true}
                  />
                </Grid>
              </Grid>
            )
          }
          {
            sfhDetails?.hardwares && sfhDetails?.hardwares.length > 0 && (
              <Grid item container justifyContent={'flex-start'} spacing={0.5}>
                <Grid item xs={12}>
                  <StyledSelectMenu
                    required={false}
                    size={SizeProps.Small}
                    id="demo-simple-select-required"
                    labelId="demo-simple-select-required-label"
                    label="Hardware"
                    selectedValue={part.hardwareRevisionId || '-1'}
                    onChange={(hardwareRevisionId) => handlePartChange({ ...part, hardwareRevisionId, hardwareRevision: getSFHDropDownSelectedLabel(hardwareRevisionId, sfhDetails.softwares) })}
                    items={sfhDetails.hardwares}
                    optionalLabelEnabled={true}
                  />
                </Grid>
              </Grid>
            )
          }
          {
            sfhDetails?.firmwares && sfhDetails?.firmwares.length > 0 && (
              <Grid item container justifyContent={'flex-start'} spacing={0.5}>
                <Grid item xs={12}>
                  <StyledSelectMenu
                    required={false}
                    size={SizeProps.Small}
                    id="demo-simple-select-required"
                    labelId="demo-simple-select-required-label"
                    label="Firmware"
                    selectedValue={part.firmwareVersionId || '-1'}
                    onChange={(firmwareVersionId) => handlePartChange({ ...part, firmwareVersionId, firmwareVersion: getSFHDropDownSelectedLabel(firmwareVersionId, sfhDetails.softwares) })}
                    items={sfhDetails.firmwares}
                    optionalLabelEnabled={true}
                  />
                </Grid>
              </Grid>
            )
          }
          {
            part?.fleetTag && part?.fleetTag?.length > 0 && (
              <Grid item container justifyContent={'flex-start'} spacing={0.5}>
                <Grid item xs={12}>
                  <StyledSelectMenu
                    required={false}
                    size={SizeProps.Small}
                    id="demo-simple-select-required"
                    labelId="demo-simple-select-required-label"
                    label="Fleet Tag"
                    selectedValue={part.fleetTagId || '-1'}
                    onChange={(fleetTagId) => handlePartChange({ ...part, fleetTagId, fleetTagName: getFleetTagDropDownSelectedLabel(fleetTagId, part.fleetTag) })}
                    items={partType.fleetTag}
                    optionalLabelEnabled={true}
                  />
                </Grid>
              </Grid>
            )
          }
        </Grid >
        {/* part  child part grid*/}
        {
          children && children.length > 0 && <Grid item container direction="column" spacing={1.0} xs={12} sm={6} md={4} mt={.5}>
            <Grid item container spacing={0.5}>
              <Grid item xs={12}>
                <Typography variant={TypographyVariantProps.H6}>Part Structure</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={0.5}>
              <StyledTableWrapper>
                <PartStructure serial={part.serial1} partStructureData={children} />
              </StyledTableWrapper>
            </Grid>
          </Grid>
        }
        {/*part history grid*/}
        <Grid item container direction="column" spacing={1.0} xs={12} sm={6} md={4} mt={.5}>
          <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.H6}>Part History</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={0.5}>
            { movementHistory?.createdByName && <Grid item container xs={12}>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>{formatDateTime(movementHistory?.createdAt)}:</Typography>
              </Grid>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>{movementHistory?.createdByName}</Typography>
              </Grid>
            </Grid>}
            { movementHistory?.historyTypeName && <Grid item container xs={12}>
              <Grid item >
                <Link href={`/services/${movementHistory?.serviceId}`}><Typography variant={TypographyVariantProps.Body1}>{movementHistory?.serviceId}:</Typography></Link>
              </Grid>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>{movementHistory?.historyTypeName}</Typography>
              </Grid>
            </Grid>}
            { movementHistory?.notes && movementHistory?.notes?.length > 0 && <Grid item container xs={12}>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>Notes:</Typography>
              </Grid>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>{movementHistory?.notes}</Typography>
              </Grid>
            </Grid>}
            {service?.reportedFaultAndDescription && <Grid item container xs={12}>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>Reported Fault:</Typography>
              </Grid>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>{service?.reportedFaultAndDescription}</Typography>
              </Grid>
            </Grid>}
            {observedFaultCode?.value?.name && observedFaultCode?.value?.name.length > 0 && <Grid item container xs={12}>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>Observed Fault:</Typography>
              </Grid>
              <Grid item >
                <Typography variant={TypographyVariantProps.Body1}>{observedFaultCode?.value.name}</Typography>
              </Grid>
            </Grid>}
          </Grid>
        </Grid>
        {/*repair history grid*/}
        <Grid item container direction="column" spacing={1.0} xs={12} sm={6} md={4} mt={.5}>
          {repairHistory && repairHistory.length > 0 && <Grid item container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.H6}>Repair History</Typography>
            </Grid>
          </Grid>}
          {repairHistory && repairHistory.length > 0 && <Grid item container spacing={0.5}>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader">
              {repairHistory && repairHistory.map((partHistory) => {
                return <RepairHistoryComponent key={partHistory.id} partHistory={partHistory} />;
              })}
            </List>
          </Grid>}
          {/*part shipment data grid*/}
          {
            latestShipment && <Grid item container direction="column" spacing={0.5} mt={.5}>
              <Grid item container spacing={0.5}>
                <Grid item xs={12}>
                  <Typography variant={TypographyVariantProps.H6}>Last Shipment</Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={0.5}>
                {latestShipment?.createdAt && <Grid item container xs={12}>
                  <Grid item >
                    <Typography variant={TypographyVariantProps.Body1}>{formatShortDate(latestShipment?.createdAt)}:</Typography>
                  </Grid>
                  <Grid item >
                    <Typography variant={TypographyVariantProps.Body1}> <Link href={`/shipments/${latestShipment.id}`} underline={UnderlineTypeProps.None}>{latestShipment.id}</Link></Typography>
                  </Grid>
                </Grid>}
                {latestShipment?.sourceLocation && latestShipment?.sourceLocation.length > 0 && <Grid item container xs={12}>
                  <Grid item >
                    <Typography variant={TypographyVariantProps.Body1}>Source:</Typography>
                  </Grid>
                  <Grid item >
                    <Typography variant={TypographyVariantProps.Body1}>{latestShipment?.sourceLocation}</Typography>
                  </Grid>
                </Grid>}
                {latestShipment?.destinationLocation && latestShipment?.destinationLocation.length > 0 && <Grid item container xs={12}>
                  <Grid item >
                    <Typography variant={TypographyVariantProps.Body1}>Destination:</Typography>
                  </Grid>
                  <Grid item >
                    <Typography variant={TypographyVariantProps.Body1}>{latestShipment?.destinationLocation}</Typography>
                  </Grid>
                </Grid>}
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid >
      <Modal
        open={open}
        onClose={handleTagBoxClose}
      >
        <Grid onMouseDown={handleTagBoxClose}>
          <StyledImage src={fullImage} />
        </Grid>
      </Modal>
      <Snackbar
        open={showSavedMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Part Updated!"
        action={action}
      />
    </Accordian >
  );
};
