import React, { useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridSelectionModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { ImageBox, RowListDataStyled } from './PartTypeList.styles';
import { Link } from '../../../components/atoms/Link';
import { PartTypeListResponse, useGetAllPartTypes } from '../../../queries/part-type-query';
import { Modal } from '../../../components/atoms/Modal';
import { ListType, NewReportDataType, OverwriteReportDataType, PropReportDataType, ReportResponse } from '../../../@types';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { PartTypeCategory, SavedReportTypeList } from '../../../configs/enums';
import { generateReportURL } from '../../../utils/report';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { PLATFORM_NAME, splitAndSentenceCase } from '../../../configs/common';
import { PART_TYPE_SERIALISED_NATURE } from '../../../constants/partType';
import { GridContainer } from '../../../styled/common.styles';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { PrintLabel } from '../../../components/molecules/PintLabel';
import { StyledImage } from '../../../components/organisms/MultipleImageUpload/MultipleImageUpload.styles';

export const PartTypeList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const reportCode = queryParams.get('savedReportCode');

  const [reportViewCode, setReportViewCode] = useState<string>();
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [reportName, setReportName] = useState('');
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [partTypes, setPartTypes] = useState<PartTypeListResponse>();
  const [fullImage, setFullImage] = useState('');
  const [open, setOpen] = useState(false);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [{ columnField: 'isActive', operatorValue: 'is', value: true }] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.PartTypeList);
  const getAllPartTypes = useGetAllPartTypes({ filterModel, page, pageSize, sortModel });

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Part Type ID',
      width: 100,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      valueGetter: (params) => params.row.id
    },
    {
      field: 'name',
      headerName: 'Part Type Name',
      renderCell: (params: GridRenderCellParams) => <Link href={`/configure/partTypes/${params.row.id}`}>{params.row.name}</Link> ,
      type: 'string',
      width: 150,
      valueGetter: (params) => params.row.name
    },
    {
      field: 'number',
      headerName: 'Part Number',
      type: 'string',
      width: 150,
      valueGetter: (params) => params.row.number
    },
    {
      field: 'partTypeCategory',
      headerName: 'Part Type Category',
      type: 'singleSelect',
      valueOptions: Object.values(PartTypeCategory).map(text => ({
        value: splitAndSentenceCase(text),
        label: splitAndSentenceCase(text)
      })),
      width: 150,
      valueGetter: (params) => params.row.partTypeCategory
    },
    {
      field: 'serialised',
      headerName: 'Serialised',
      type: 'singleSelect',
      valueOptions: PART_TYPE_SERIALISED_NATURE,
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.serialised
    },
    {
      field: 'client',
      headerName: 'Client',
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.client
    },
    {
      field: 'contract',
      headerName: 'Contract(s)',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => params.row.contract
    },
    {
      field: 'photo',
      headerName: 'Photo',
      type: 'string',
      width: 200,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => params.row?.photo && <ImageBox src={params.row?.photo} onClick={() => onOpenPicture(params.row?.photo)}/>,
      valueGetter: (params: GridValueGetterParams) => params.row?.photo
    },
    {
      field: 'childPartTypeIds',
      headerName: 'Child Part Type IDs',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (params.row?.childPartTypeIds && <RowListDataStyled>{params.row.childPartTypeIds}</RowListDataStyled>)
    },
    {
      field: 'childPartTypeNames',
      headerName: 'Child Part Type Names',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (params.row?.childPartTypeNames && <RowListDataStyled>{params.row.childPartTypeNames}</RowListDataStyled>)
    },
    {
      field: 'childPartTypeQuantities',
      headerName: 'Child Part Type Quantities',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (params.row?.childPartTypeQuantities && <RowListDataStyled>{params.row.childPartTypeQuantities}</RowListDataStyled>)
    },
    {
      field: 'salePrice',
      headerName: 'Sale Price',
      width: 200,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      renderCell: (params: GridRenderCellParams) => params.row.salePrice
    },
    {
      field: 'supplierPrice',
      headerName: 'Supplier Price',
      width: 200,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      renderCell: (params: GridRenderCellParams) => params.row.supplierPrice
    },
    {
      field: 'partTypeSupplier',
      headerName: 'Part Type (Supplier)',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => params.row.partTypeSupplier
    },
    {
      field: 'partNumberSupplier',
      headerName: 'Part Number (Supplier)',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => params.row.partNumberSupplier
    },
    {
      field: 'isActive',
      headerName: 'Active',
      type: 'singleSelect',
      width: 200,
      valueOptions: [
        { value: 'any', label: 'Any' },
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
      ],
      valueGetter: (params: GridValueGetterParams) => params.row?.isActive ? 'Yes' : 'No'
    },
    {
      field: 'printLabel',
      headerName: 'Barcode Print Button',
      type: 'string',
      width: 200,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        <PrintLabel entityId={Number(params.row.id) || 0} entityType={PrintLabelEntityTypes.PartType} />
      )
    }
  ]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  useEffect(() => {
    getAllPartTypes.refetch();  
  }, [page, pageSize, filterModel, sortModel]);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.PartTypeList]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
      defaultReportData.data[0].dataGridProperties?.columnOrder && setColumns(defaultReportData.data[0].dataGridProperties?.columnOrder);
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);

  useEffect(() => {
    if (reportCode && reportQueryData && firstLoad) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Part Types | ${PLATFORM_NAME}`;
      
      setFilterModel(reportQueryData.dataGridProperties?.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties?.pageSize);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
      reportQueryData.dataGridProperties?.columnOrder && setColumns(reportQueryData.dataGridProperties?.columnOrder);
      setFirstLoad(false);
    }   
  }, [reportQueryData, reportViewCode, columns, firstLoad]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || [],
        columnOrder: columns
      },
      reportTypeName: SavedReportTypeList.PartTypeList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel, columns]);

  useEffect(() => {
    if ((!reportCode || (reportCode && reportQueryData)) && getAllPartTypes.data) {
      setPartTypes(getAllPartTypes.data);
    }
  }, [getAllPartTypes.data, reportCode, isDefault, reportQueryData]);

  const onOpenPicture = async (image: any) => {
    if (image) {
      setFullImage(image);
      setOpen(true);
    }
  };

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true); 
    navigate(reportURL, { replace: true });
  };

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Part Type',
      value: [
        {
          title: 'Part Type ID',
          field: 'id',
          value: true
        },
        {
          title: 'Part Type Name',
          field: 'name',
          value: true
        },
        {
          title: 'Part Number',
          field: 'number',
          value: true
        },
        {
          title: 'Part Type Category',
          field: 'partTypeCategory',
          value: true
        },
        {
          title: 'Serialised',
          field: 'serialised',
          value: true
        },
        {
          title: 'Client',
          field: 'client',
          value: true
        },
        {
          title: 'Contract(s)',
          field: 'contract',
          value: true
        },
        {
          title: 'Photo',
          field: 'photo',
          value: true
        },
        {
          title: 'Barcode Print Button',
          field: 'barcodePrintButton',
          value: true
        }
      ]
    },
    {
      title: 'Show Child Parts',
      value: [
        {
          title: 'Child Part Type IDs',
          field: 'childPartTypeIds',
          value: false
        },
        {
          title: 'Child Part Type Names',
          field: 'childPartTypeNames',
          value: false
        },
        {
          title: 'Child Part Type Quantities',
          field: 'childPartTypeQuantities',
          value: false
        }
      ]
    },
    {
      title: 'Pricing',
      value: [
        {
          title: 'Sale Price',
          field: 'salePrice',
          value: false
        },
        {
          title: 'Supplier Price',
          field: 'supplierPrice',
          value: false
        },
        {
          title: 'Part Type (Supplier)',
          field: 'partTypeSupplier',
          value: false
        },
        {
          title: 'Part Number (Supplier)',
          field: 'partNumberSupplier',
          value: false
        }
      ]
    }
  ];

  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={partTypes?.data || []}
        filterModel={filterModel}
        rowCount={partTypes?.total}
        exportFileName={ListType.PartTypeList}
        enableCSVExport={true}
        columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        onFilterModelChange={(filterModel: GridFilterModel) => onFilterChange(filterModel)}
        loading={getAllPartTypes.isLoading}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        sortModel={sortModel}
        sortingMode={GridFeatureModeConstant.server}
        onSortModelChange={handleSortModelChange}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        initialState={{
          columns: {
            columnVisibilityModel: {
              parentNodeId: false,
              referenceNumbers: false,
              status: false,
              roleId: false
            },
          },
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        newReportData={newReportData} 
        handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
        handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
        enableSavedReport={true}
        reportCode={reportViewCode}
        handleColumnsChange={handleColumnsChange}
        handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
        getRowHeight={() => 'auto'}
        setColumns={setColumns}
      />
      <Grid>
        <Snackbar  
          open={openToast} 
          autoHideDuration={4000} 
          message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
          onClose={()=> setOpenToast(false)}
        />
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid onMouseDown={() => setOpen(false)}>
          <StyledImage src={fullImage}/>
        </Grid>
      </Modal>
    </GridContainer>
  );
};