import React from 'react';
import { FaultDefinitionProps } from './FaultDefinition.props';
import { Table, TableRow, TableCell } from '@mui/material';
import { TextField } from '../../atoms/TextField';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { HeadingCell, StyledTableHead } from './FaultDefinition.styles';

export const FaultDefinition: React.FC<FaultDefinitionProps> = ({
  faultDefinitaionData,
  showHeader = false,
  isParentFaultDefinition = false,
  testDataId = '',
  onChange
}) => {
  return (
    <Table size="small">
      <StyledTableHead isParent={isParentFaultDefinition}>
        {showHeader &&
          <TableRow>
            <HeadingCell>
              Name
            </HeadingCell>
            <HeadingCell>
              Customer ID
            </HeadingCell>
            <HeadingCell>
              Customer ID 2
            </HeadingCell>
            <HeadingCell>
              Active
            </HeadingCell>
          </TableRow>}
        <TableRow>
          <TableCell>
            <TextField
              value={faultDefinitaionData.name}
              fullWidth={true}
              inputProps={{
                'data-testid': `${testDataId}-name`
              }}
              onChange={(text) => onChange({
                ...faultDefinitaionData,
                name: text
              })}></TextField>
          </TableCell>
          <TableCell>
            <TextField
              value={faultDefinitaionData.customerId}
              fullWidth={true}
              inputProps={{
                'data-testid': `${testDataId}-customerId`
              }}
              onChange={(text) => onChange({
                ...faultDefinitaionData,
                customerId: text
              })}></TextField>
          </TableCell>
          <TableCell>
            <TextField
              value={faultDefinitaionData.customerId2}
              fullWidth={true}
              inputProps={{
                'data-testid': `${testDataId}-customerId2`
              }}
              onChange={(text) => onChange({
                ...faultDefinitaionData,
                customerId2: text
              })}></TextField>
          </TableCell>
          <TableCell>
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={faultDefinitaionData.isActive}
              testDataId={`${testDataId}-active`}
              onChange={(event) => onChange({
                ...faultDefinitaionData,
                isActive: event.target.checked
              })} />
          </TableCell>
        </TableRow>
      </StyledTableHead>
    </Table>
  );
};