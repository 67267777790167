import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DateTimePickerProps } from './DateTimePicker.props';
import { StyledDateTimePicker } from './DateTimePicker.styles';
import { SizeProps } from '../../../@types';

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label = 'DateTime',
  disabled = false,
  disableFuture = false,
  disablePast = false,
  value,
  error = false,
  onChange,
  isFocused = false,
  isCompact = false
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(isFocused);
  }, [isFocused]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDateTimePicker
        label={label}
        isCompact={isCompact}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disabled={disabled}
        inputFormat="YYYY-MM-DD HH:mm"
        disableMaskedInput={true}
        disableFuture={disableFuture}
        disablePast={disablePast}
        maxDateTime={disableFuture ? new Date() : null}
        minDateTime={disablePast ? dayjs() : null}
        value={dayjs(value, 'YYYY-MM-DD HH:mm')}
        onChange={(text: any) => onChange(text?.format('YYYY-MM-DD HH:mm') || '')}
        renderInput={(params) => <TextField {...params} size={SizeProps.Small} fullWidth error={error}/>}
      />    
    </LocalizationProvider>  
  );
};