import { ColumnSelectionItem } from '../components/templates/ColumnSelection';
import { SAMPLEROLE } from './role.constant';
import { TAGS } from './tag.constant';
import { SAMPLETIMEZONE } from './timezone.constant';

export const SAMPLEUSER = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  contactEmail: '',
  auth0Id: '',
  isActive: true,
  isLocked: false,
  isEmailVerified: true,
  profilePic: '',
  timezoneId: -1,
  timezone: SAMPLETIMEZONE,
  notes: '',
  lastActive: '',
  systemRoleId: -1,
  structureId: 0,
  role: SAMPLEROLE,
  tags: [TAGS],
  createdAt: '',
  updatedAt: '',
  siblingNodeId: 0,
  siblingNodeName: '',
  siblingAttributes: []
};

export const SAMPLEUSERHELPERTEXT = {
  firstNameHelperText: '',
  lastNameHelperText: '',
  EmailHelperText: '',
  nodeNameHelperText: '',
};

export const SAMPLEIDENTITIES = {
  connection: ''
};

export const SAMPLEAUTH0USER = {
  blocked: false,
  email_verified: false,
  email: '',
  given_name: '',
  family_name: '',
  name: '',
  updated_at: '',
  picture: '',
  account_enabled: false,
  azure_id: '',
  identities: [SAMPLEIDENTITIES],
};

export const SAMPLE_LOGIN_USER = {
  userName: '',
  profilePic: ''
};

export const userListColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'User',
    value: [
      {
        title: 'User ID',
        field: 'id',
        value: true
      },
      {
        title: 'First Name',
        field: 'firstName',
        value: true
      },
      {
        title: 'Last Name',
        field: 'lastName',
        value: true
      },
      {
        title: 'Profile Photo',
        field: 'profilePic',
        value: true
      },
      {
        title: 'Login Email',
        field: 'email',
        value: true
      },
      {
        title: 'Tags',
        field: 'tags',
        value: true
      },
      {
        title: 'Is Locked',
        field: 'isLocked',
        value: true
      },
      {
        title: 'Email Verified',
        field: 'isEmailVerified',
        value: true
      },
      {
        title: 'Last Active',
        field: 'lastActive',
        value: true
      },
      {
        title: 'Contact Email',
        field: 'contactEmail',
        value: true
      },
      {
        title: 'Time Zone',
        field: 'timezoneName',
        value: true
      },
      {
        title: 'Enabled',
        field: 'isActive',
        value: true
      }
    ]
  },
  {
    title: 'Sibling',
    value: [
      {
        title: 'Sibling ID',
        field: 'siblingNodeId',
        value: true
      },
      {
        title: 'Node Name',
        field: 'siblingNodeName',
        value: true
      },
      {
        title: 'Parent Node Name',
        field: 'siblingParentNodeName',
        value: true
      },
      {
        title: 'Parent Node Tree',
        field: 'siblingParentNodeTree',
        value: true
      },
      {
        title: 'Node Adresses',
        field: 'siblingNodeAddresses',
        value: true
      },
      {
        title: 'Node Contacts',
        field: 'siblingNodeContacts',
        value: true
      },
      {
        title: 'Reference Number 1',
        field: 'siblingRefNumber1',
        value: true
      },
      {
        title: 'Reference Number 2',
        field: 'siblingRefNumber2',
        value: true
      },
      {
        title: 'Reference Number 3',
        field: 'siblingRefNumber3',
        value: true
      }
    ]
  },
  {
    title: 'Permissions',
    value: [
      {
        title: 'Role ID',
        field: 'roleId',
        value: true
      },
      {
        title: 'Role Name',
        field: 'roleName',
        value: true
      },
      {
        title: 'Role Visibility Rule',
        field: 'roleVisibilityRule',
        value: true
      },
      {
        title: 'Role Active',
        field: 'roleActive',
        value: true
      },
      {
        title: 'Role Notes',
        field: 'roleNotes',
        value: true
      },
      {
        title: 'User Custom Permissions',
        field: 'roleUserCustomPermissions',
        value: true
      },
    ]
  },
  {
    title: 'Misc',
    value: [
      {
        title: 'User Created At',
        field: 'userCreatedAt',
        value: true
      },
      {
        title: 'User Created By',
        field: 'userCreatedBy',
        value: true
      },
      {
        title: 'User Updated At',
        field: 'userUpdatedAt',
        value: true
      },
      {
        title: 'User Updated By',
        field: 'userUpdatedBy',
        value: true
      },
      {
        title: 'Sibling Created At',
        field: 'siblingCreatedAt',
        value: true
      },
      {
        title: 'Sibling Created By',
        field: 'siblingCreatedBy',
        value: true
      },
      {
        title: 'Sibling Updated At',
        field: 'siblingUpdatedAt',
        value: true
      },
      {
        title: 'Sibling Updated By',
        field: 'siblingUpdatedBy',
        value: true
      },
    ]
  },
];

export const CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE = 'Invalid character or characters included in ';
export const CHARACTER_LENGTH_MESSAGE = 'Character limit exceeded. The system accept up to 64 characters';
export const INVALID_EMAIL_MESSAGE = 'Invalid email format';
export const EMAIL_ALREADY_EXISTS_MESSAGE = 'This email already exist in Auth0';
export const CONTAIN_ONLY_ALPHABETICS_NUMBERS_AND_CHARACTERS_MESSAGE =
  'Field only accept alphanumerical letters and specific characters(A-Z, a-z, 0-9, !@#$&-) include in ';
export const CHARACTER_CUSTOM_LENGTH_MESSAGE = 'Character limit exceeded. The system accept up to ';
export const NODE_NAME_CANNOT_EMPTY = 'Node Name field cannot be empty';