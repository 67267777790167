import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { AttendanceDataType, AttendanceList } from '../../../../components/molecules/AttendanceList';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { DropdownItem } from '../../../../components/atoms/SelectMenu';
import { SLADetails, SLADetailsData } from '../../../../components/templates/SLADetails';
import { ServiceHistoryFragmentProps } from './ServiceAdministrationFragment.props';
import { useGetAllServiceAttendance, useUpdateBillingInfo } from '../../../../queries/service-query';
import { InvoiceType, ServiceBilling, ServiceBillingData } from '../../../../components/organisms/ServiceBilling';
import { SAMPLE_SERVICE_BILLING, SAMPLE_SLA_DETAILS } from '../../../../constants/sla';
import { useGetAllOutcomeReasons } from '../../../../queries/outcome-reason-query';
import { useGetSLAsByServiceId, useResetSLAById, useRefreshSLAsByServiceId, useResetSLAsByServiceId, useUpdateSLATimeoutAt } from '../../../../queries/sla-query';
import { useCreateOutcomeReview } from '../../../../queries/outcome-review-query';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { Invoice, OutcomeReason, OutcomeTypeCode, SLASnackbarText, Warning } from '../../../../@types';
import { SLAContactRowData } from '../../../../components/organisms/SLAContactRow';

export const ServiceAdministrationFragment: React.FC<ServiceHistoryFragmentProps> = ({
  data,
  adminPanelOnTop,
  onClick,
  expandOnMobile
}) => {
  const [billingData, setBillingData] = useState<ServiceBillingData>(SAMPLE_SERVICE_BILLING);
  const [attendanceData, setAttendanceData] = useState<AttendanceDataType[]>([]);
  const [contactRowData, setContactRowData] = useState<SLADetailsData>(SAMPLE_SLA_DETAILS);
  const [reviewValue, setReviewValue] = useState<string>('-1');
  const [mitigationValue, setMitigationValue] = useState<string>('-1');
  const [text, setText] = useState<string>('');
  const [invoiceFormData, setInvoiceFormData] = useState<Invoice[]>([]);
  const [warning, setWarning] = useState<Warning>();
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [reviewOptions, setReviewOptions] = useState<Array<DropdownItem>>([]);
  const [mitigationOptions, setMitigationOptions] = useState<Array<DropdownItem>>([]);
  const [outcomeTypeCode, setOutcomeTypeCode] = useState<string>(OutcomeTypeCode.Reviewed);
  const [open, setOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [billingStatus, setBillingStatus] = useState(false);

  const getServiceAttendancesByServiceIdQuery = useGetAllServiceAttendance(data.id);
  const updateBillingInfo = useUpdateBillingInfo(data.id);
  const getAllOutcomeReasons = useGetAllOutcomeReasons();
  const getSLAsByServiceId = useGetSLAsByServiceId(data.id);
  const createOutcomeReview = useCreateOutcomeReview(data.id);
  const updateSLATimeoutAt = useUpdateSLATimeoutAt();
  const resetSLAByServiceId = useResetSLAsByServiceId(data.id);
  const resetSLAById = useResetSLAById(data.id);
  const refreshSLAsByServiceId = useRefreshSLAsByServiceId(data.id);

  useEffect(() => {
    if (data?.adminDetails?.billingInfo) {
      const values = data?.adminDetails?.billingInfo;
      const invoices = values.invoices;
      setBillingData({
        billingReviewed: values.isReviewed,
        billableType: values.billingTypeCode,
        clientInvoiced: values.isClientInvoiced,
        billingNotes: values.billingNotes,
        privateNotes: values.privateNotes,
        externalInvoices: invoices.filter((item) => item.invoiceType === InvoiceType.External),
        internalInvoices: invoices.filter((item) => item.invoiceType === InvoiceType.Internal)
      });
    }
  }, [data]);

  useEffect(() => {
    getServiceAttendancesByServiceIdQuery.data && setAttendanceData(getServiceAttendancesByServiceIdQuery.data);
  }, [getServiceAttendancesByServiceIdQuery.data]);

  useEffect(() => {
    getSLAsByServiceId.data && setContactRowData({
      contactRowData: getSLAsByServiceId.data,
      reviewMitigationData: {
        reviewValue: reviewValue,
        reviewNote: '',
        mitigationValue: mitigationValue,
        mitigationNote: ''
      }
    });
  }, [getSLAsByServiceId.data]);

  useEffect(() => {
    if (getAllOutcomeReasons.data) {
      const reviews = getAllOutcomeReasons.data.filter((reason: OutcomeReason) => reason.outcomeTypeCode === OutcomeTypeCode.Reviewed);
      const mitigates = getAllOutcomeReasons.data.filter((mitigate: OutcomeReason) => mitigate.outcomeTypeCode === OutcomeTypeCode.Mitigated);

      reviews.map((review: any) => {
        reviewOptions.push({
          label: review?.name,
          value: review.id.toString(),
        });
      });
      setReviewOptions(reviewOptions);

      mitigates.map((mitigate: any) => {
        mitigationOptions.push({
          label: mitigate?.name,
          value: mitigate.id.toString(),
        });
      });
      setMitigationOptions(mitigationOptions);
    }
  }, [getAllOutcomeReasons.data]);

  const handleServiceBillingChange = async(value: ServiceBillingData) => {
    let warningsForInvoiceDetails;
    if (invoiceFormData.length) {
      warningsForInvoiceDetails = {
        field: 'Invoice',
        message: 'There are unsaved invoice details. Please save them before proceeding',
      };
      setWarning(warningsForInvoiceDetails);
      setShowWarningToast(!!warningsForInvoiceDetails);
      return;
    }

    value.internalInvoices.map((item) => item.invoiceType = InvoiceType.Internal);
    value.externalInvoices.map((item) => item.invoiceType = InvoiceType.External);

    const billing = {
      billingTypeCode: value.billableType,
      isReviewed: value.billingReviewed,
      isClientInvoiced: value.clientInvoiced,
      billingNotes: value.billingNotes,
      privateNotes: value.privateNotes,
      invoices: [...value.internalInvoices, ...value.externalInvoices]
    };

    await updateBillingInfo.mutateAsync(billing);
    setBillingStatus(true);
  };

  const handleSLADetailsChange = async (value: any) => {
    let outcomeReasonId;
    let notes;
    const SLAIds: number[] = [];

    if (outcomeTypeCode === 'reviewed') {
      outcomeReasonId = value.reviewMitigationData.reviewValue;
      notes = value.reviewMitigationData.reviewNote;
      setReviewValue(outcomeReasonId);
      setText(SLASnackbarText.Review);
    } else {
      outcomeReasonId = value.reviewMitigationData.mitigationValue;
      notes = value.reviewMitigationData.mitigationNote;
      setMitigationValue(outcomeReasonId);
      setText(SLASnackbarText.Mitigation);
    }

    value.contactRowData.filter((filteredSLA: any) => filteredSLA.isChecked).map((sla: any) => {
      SLAIds.push(sla.id);
    });

    if (SLAIds.length === 0) {
      setWarningOpen(true);
      return;
    }

    const reviewOutcome = {
      outcomeTypeCode: outcomeTypeCode,
      outcomeReasonId: parseInt(outcomeReasonId),
      description: notes,
      notes: notes,
      slaIds: SLAIds
    };

    await createOutcomeReview.mutateAsync(reviewOutcome);
    getSLAsByServiceId.refetch();
    setOpen(true);
  };

  const handleTabChange = (tabType: string) => {
    setOutcomeTypeCode(tabType === SLASnackbarText.Review ? OutcomeTypeCode.Reviewed : OutcomeTypeCode.Mitigated);
  };

  const handleSLADateChange = async (sla: SLAContactRowData) => {
    const updatedSla = {
      id: sla.id,
      timeoutAt: sla.loggedSLADateTime
    };

    await updateSLATimeoutAt.mutateAsync(updatedSla);
    getSLAsByServiceId.refetch();
  };

  const handleSLARest = async (slaId?: number) => {
    if (slaId) {
      await resetSLAById.mutateAsync({ slaId });
    }
    else {
      await resetSLAByServiceId.mutateAsync({});
    }
  };

  const handleSLARefresh = async () => {
    await refreshSLAsByServiceId.mutateAsync({});
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setBillingStatus(false);
    setOpen(false);
    setWarningOpen(false);
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  return (
    <Accordian
      title={
        <Grid container alignItems="center">
          <Grid item mr={2}>
            <Typography>Administration</Typography>
          </Grid>
          <Grid item mr={2}>
            {adminPanelOnTop ? <ArrowDownward onClick={onClick} /> : <ArrowUpward onClick={onClick} />}
          </Grid>
        </Grid>
      }
      defaultExpanded={false || expandOnMobile}
    >
      <ServiceBilling
        billingData={billingData}
        onChange={(value: ServiceBillingData) => setBillingData(value)}
        onSave={handleServiceBillingChange}
        onAddForm={(formData) => setInvoiceFormData(formData)}
      />
      <AttendanceList
        attendanceData={attendanceData}
      />
      <SLADetails
        isReview={true}
        isMitigation={true}
        outcomeTypeCode={outcomeTypeCode}
        reviewOptions={reviewOptions}
        mitigationOptions={mitigationOptions}
        slaDetailsValue={contactRowData}
        onSLADetailsChange={(val) => setContactRowData(val)}
        onSave={handleSLADetailsChange}
        onTabChange={handleTabChange}
        handleSLADateChange={handleSLADateChange}
        handleSLARest={handleSLARest}
        handleSLARefresh={handleSLARefresh}
      />
      <Snackbar
        open={billingStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Billing details updated!"
        action={action}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`${text} added!`}
        action={action}
      />
      <Snackbar
        open={warningOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Select at least one breached SLA"
        action={action}
      />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={8000} onClose={() => { setShowWarningToast(false); }}>
        <Alert onClose={() => { setShowWarningToast(false); }} severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Box>{warning?.message}</Box>
        </Alert>
      </Snackbar>
    </Accordian>
  );
};