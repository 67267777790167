import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Stack } from '@mui/material';
import * as XLSX from 'xlsx';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { AddStockDetailFields, DispatchDetail, DispatchDetailHelperText, DispatchPrefillData, ManifestData, SavedManifestItem, ShipmentStatusCodes } from '../../../@types/shipment.type';
import { DISPATCH_DETAILS_HELPER_TEXT, SAMPLE_DISPATCH_DETAIL } from '../../../constants/shipment';
import { DispatchDetailsFragment } from './DispatchDetailsFragment/DispatchDetailsFragment';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../components/atoms/Button';
import { hasErrorInDispatchDetails } from '../../../utils/shipment';
import { useCreateShipment, useCreateShipmentServiceAllocation, useGetOneShipment, useGetShipmentServiceAllocationsByShipmentId, useUpdateShipment } from '../../../queries/shipment-query';
import { nameValidation } from '../../../utils/common';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { ShippingMethodFragment } from './ShippingMethodFragment';
import { ManualShippingData } from '../../../components/organisms/ManualShipping';
import { DispatchManifestFragment } from './DispatchManifestFragment';
import { DispatchPrefillParams, LogTypeNames } from '../../../configs/enums';
import { Box } from '../../../components/atoms/Box';
import { DispatchProps } from './Dispatch.props';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const Dispatch: React.FC<DispatchProps> = ({
  dispatchData,
  onDispatch
}) => {
  const { id } = dispatchData ? { id: 'create' } : useParams();
  const isNew = id === 'create' || !!dispatchData;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatchPrefillData: DispatchPrefillData = {
    serviceId: dispatchData?.serviceId as number || parseInt(searchParams.get(DispatchPrefillParams.ServiceId) || '0'),
    locationId: dispatchData?.mainLocationNodeId as number || parseInt(searchParams.get(DispatchPrefillParams.LocationId) || '0'),
    locationName: dispatchData?.mainLocation || searchParams.get(DispatchPrefillParams.LocationName) || '',
    subLocationNodeId: dispatchData?.subLocationNodeId,
    primaryAllocationNodeId: dispatchData?.primaryAllocationNodeId,
    secondaryAllocationNodeId: dispatchData?.secondaryAllocationNodeId,
    isFromServiceEdit: dispatchData?.isFromServiceEdit || searchParams.get(DispatchPrefillParams.isFromServiceEdit) === 'true' || false
  };

  const [dispatchDetail, setDispatchDetail] = useState<DispatchDetail>(SAMPLE_DISPATCH_DETAIL);
  const [manifest, setManifest] = useState<Array<ManifestData>>([]);
  const [exportManifest, setExportManifest] = useState<Array<ManifestData>>([]);
  const [savedManifest, setSavedManifest] = useState<Array<SavedManifestItem>>([]);
  const [helperText, setHelperText] = useState<DispatchDetailHelperText>(DISPATCH_DETAILS_HELPER_TEXT);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [linkedServiceIds, setLinkedServiceIds] = useState<Array<number>>([]);

  const hasError = hasErrorInDispatchDetails(
    dispatchDetail.source,
    dispatchDetail.destination
  );

  const createShipment = useCreateShipment();
  const updateShipment = useUpdateShipment();
  const getOneShipment = useGetOneShipment(id ? parseInt(id) : 0);
  const createShipmentServiceAllocationQuery = useCreateShipmentServiceAllocation();
  const getShipmentServiceAllocationsQuery = useGetShipmentServiceAllocationsByShipmentId(id ? parseInt(id) : 0);

  !dispatchData && TabHandler(isNew ? `Dispatch | ${PLATFORM_NAME}` : `Dispatch: ${id} | Dispatch | ${PLATFORM_NAME}`);

  useEffect(() => {
    if (!isNew && getOneShipment.data) {
      setDispatchDetail(getOneShipment.data);
      setSavedManifest(getOneShipment.data?.manifest ?? []);
    }
  }, [isNew, getOneShipment.data]);
  
  useEffect(() => {
    if (!isNew && getShipmentServiceAllocationsQuery.data) {
      setLinkedServiceIds(getShipmentServiceAllocationsQuery.data?.map((serviceAllocation) => serviceAllocation.serviceId) ?? []);
    }
  }, [isNew, getShipmentServiceAllocationsQuery.data]);

  const handleSaveOrUpdate = async () => {
    setHelperText({ 
      ...helperText, 
      sourceHelperText: nameValidation(dispatchDetail.source, AddStockDetailFields.SourceField),
      destinationHelperText: nameValidation(dispatchDetail.destination, AddStockDetailFields.DestinationField),
    });

    if (hasError) {
      return;
    }

    if (isNew) {
      const dataToSave = {
        ...dispatchDetail,
        manifest: manifest.filter((item) => item.selected)
      };
  
      if (dataToSave.manifest.length === 0) {
        setShowWarningToast(true);
        return;
      }
      const shipment: any = await createShipment.mutateAsync(dataToSave);
      setOpenToast(true);
      if (dispatchPrefillData.serviceId || dispatchDetail.serviceId) {
        const serviceId = dispatchPrefillData.serviceId ? dispatchPrefillData.serviceId : dispatchDetail.serviceId;
        const shipmentLink: any = {
          shipmentId: shipment.id,
          serviceId: serviceId,
          logType: LogTypeNames.NewShipmentCreated
        };
        await createShipmentServiceAllocationQuery.mutateAsync(shipmentLink);
      }
      
      if (!dispatchPrefillData.isFromServiceEdit) {
        navigate(`/shipments/${shipment.id}`);
      } else {
        onDispatch && onDispatch({ openFragment: false, serviceId: 0, mainLocation: '', mainLocationNodeId: 0, isFromServiceEdit: true });
      }
    } else {
      await updateShipment.mutateAsync(dispatchDetail);
      setOpenToast(true);
    }
  };

  const handleExport = async () => {
    if (exportManifest.filter((item) => item.selected).length === 0) {
      setShowWarningToast(true);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updatedExportManifest = exportManifest.map(({ selected, ...item }) => item);

    const worksheet = XLSX.utils.json_to_sheet(updatedExportManifest);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'shipment-manifest.xlsx');
  };

  const handleSaveManualShippingMethod = async (dataToSave: ManualShippingData) => {
    await updateShipment.mutateAsync({ ...dispatchDetail, ...dataToSave });
    setOpenToast(true);
  };

  return (
    <PageContainer>
      {dispatchData?.isFromServiceEdit ?
        <Stack mt="-20px"><Typography>Dispatch</Typography></Stack>
        :
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          Dispatch{!isNew && ` - #${id}`}
        </Typography>
      }
      <DispatchDetailsFragment 
        dispatchDetail={dispatchDetail} 
        setDispatchDetail={setDispatchDetail} 
        isNew={isNew}
        helperText={helperText}
        setHelperText={setHelperText}
        prefillData={dispatchPrefillData}
        clientSelectionDisabled={manifest.length > 0}
        linkedServiceIds={linkedServiceIds}
        isDisabled={dispatchDetail.shipmentStatusCode === ShipmentStatusCodes.Complete || dispatchDetail.shipmentStatusCode === ShipmentStatusCodes.Received}
      />
      <DispatchManifestFragment 
        applicableNodeId={dispatchDetail.clientHierarchySearch || ''}
        sourceLocationId={dispatchDetail.sourceNodeId}
        sourceLocationName={dispatchDetail.source}		
        shipmentId={!isNew ? parseInt(id || '0') : 0}
        manifestData={savedManifest}
        onChangeManifestData={(val) => setManifest(val)}
        onChangeExportManifestData={(val) => setExportManifest(val)}
        onExport={handleExport}
        isNew={isNew}      
      />
      {(!isNew && (dispatchDetail.shipmentStatusCode === ShipmentStatusCodes.Complete || dispatchDetail.shipmentStatusCode === ShipmentStatusCodes.Cancelled)) || (
        <Stack direction="row" justifyContent="right" mr={1}>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Success} onClick={handleSaveOrUpdate}>
            {isNew ? 'Submit' : 'Update'}
          </Button>
        </Stack>
      )}
      {!isNew && <ShippingMethodFragment shipment={dispatchDetail} onSaveManual={handleSaveManualShippingMethod}/>}
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message={isNew ? 'Successfully Saved' : 'Successfully Updated'}
        onClose={() => setOpenToast(false)}
      />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={8000} onClose={() => { setShowWarningToast(false); }}>
        <Alert onClose={() => { setShowWarningToast(false); }} severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Box>Please add items to manifest</Box>
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};
