import React from 'react';
import { Home } from '../pages/Home/Home';
import { MainLayout } from '../components/organisms/MainLayout';
import { Roles, AddEditRole } from '../pages/Roles';
import { AddEditUser, Users } from '../pages/Users';
import { StructureTypes, AddEditStructureType } from '../pages/StructureTypes';
import { Structures } from '../pages/Structures';
import { ClientAndContract } from '../pages/ClientStructure/ClientAndContract';
import { AddEditClient } from '../pages/ClientStructure/AddEditClient';
import { AddEditServiceStatus } from '../pages/ServiceStatuses/AddEditServiceStatus/AddEditServiceStatus';
import { ServiceStatuses } from '../pages/ServiceStatuses';
import { ServiceTypes } from '../pages/ServicesTypes/ServiceTypes';
import { AddEditServiceType } from '../pages/ServicesTypes/AddEditServiceType';
import { AddEditService } from '../pages/Services/AddEditService/AddEditService';
import { Services } from '../pages/Services/Services';
import { SLARuleList } from '../pages/SLA/SLARules';
import { AddEditPartType } from '../pages/PartType/AddEditPartType/AddEditPartType';
import { PartTypes } from '../pages/PartType';
import { AllocationRuleList } from '../pages/Allocations/AllocationRules';
import { UpdatePart } from '../pages/Part/UpdatePart';
import { PartList } from '../pages/Part/PartList';
import { ImportStructure } from '../pages/Structures/ImportStructures';
import { ReplenishmentConfigs } from '../pages/ReplenishmentConfigs/ReplenishmentConfigs';
import { SKURuleList } from '../pages/SKU/SKURuleList';
import { AddEditStock } from '../pages/Shipments/AddStock';
import { EditShipment } from '../pages/Shipments';
import { ShipmentList } from '../pages/Shipments/ShipmentList';
import { AttendanceReportList } from '../pages/Reports/AttendanceReportList';
import { ReplenishmentReportList } from '../pages/Reports/ReplenishmentReportList';
import { ImportService } from '../pages/Services/ImportServices';
import { Dispatch } from '../pages/Shipments/Dispatch';
import { IndividualShipmentReceive } from '../pages/Shipments/StockReceivals/IndividualShipmentReceive';
import { MyReportsList } from '../pages/Reports/MyReportsList';
import { BulkUpdate } from '../pages/Part/BulkUpdate';
import { InteractionsRules } from '../pages/Interactions/Rules/InteractionsRules';
import { NotificationRules } from '../pages/Notifications/NotificationRules/NotificationRules';
import { NotificationTemplates } from '../pages/Notifications/NotificationTemplates/NotificationTemplates';
import { BatchReceiveList } from '../pages/Shipments/BatchReceiveList';
import { InteractionsTemplate } from '../pages/Interactions/Templates/InteractionTemplate';
import { Return } from '../pages/Shipments/Return';
import { AddEditBatchReceive } from '../pages/Shipments/AddEditBatchReceive';
import { PartMovementList } from '../pages/Part/MovementList';
import { TLC } from '../pages/Part/TLC';
import { AddEditTemplate } from '../pages/Part/TLCTemplates/AddEditTemplate';
import { TLCTemplate } from '../pages/Part/TLCTemplates';
import { NavigatorSupport } from '../pages/NavigatorSupport/NavigatorSupport';
import { AddEditStructureLocationWrapper } from '../pages/Structures/AddEditStructureLocation/StructureLocationPageWrapper';

const routes = [];

export const home = {
  path: '/',
  element: <Home />
};

routes.push(
  {
    path: '/configure/users',
    element: <Users />
  },
  {
    path: '/configure/users/:id',
    element: <AddEditUser />
  },
  {
    path: '/configure/users/roles',
    element: <Roles />
  },
  {
    path: '/configure/users/roles/:id',
    element: <AddEditRole />
  },
  {
    path: '/configure/structure/types',
    element: <StructureTypes />
  },
  {
    path: '/configure/structure/types/:id',
    element: <AddEditStructureType />
  },
  {
    path: '/services',
    element: <Services />
  },
  {
    path: '/services/:id',
    element: <AddEditService />
  },
  {
    path: '/services/import',
    element: <ImportService />
  },
  {
    path: '/structure/search',
    element: <Structures />
  },
  {
    path: '/structure/:id',
    element: <AddEditStructureLocationWrapper />
  },
  {
    path: '/structure/import',
    element: <ImportStructure />
  },
  {
    path: '/configure/services/statuses',
    element: <ServiceStatuses />
  },
  {
    path: '/configure/services/types',
    element: <ServiceTypes />
  },
  {
    path: '/configure/clients',
    element: <ClientAndContract />
  },
  {
    path: '/configure/services/types/:code',
    element: <AddEditServiceType />
  },
  {
    path: '/configure/clients/:type/:id',
    element: <AddEditClient />
  },
  {
    path: '/configure/services/statuses/:code',
    element: <AddEditServiceStatus />
  },
  {
    path: '/configure/sla/rules',
    element: <SLARuleList />
  },
  {
    path: '/configure/partTypes/:id',
    element: <AddEditPartType />
  },
  {
    path: '/configure/partTypes',
    element: <PartTypes />
  },
  {
    path: '/configure/allocation/rules',
    element: <AllocationRuleList />
  },
  {
    path: '/parts/search',
    element: <PartList />
  },
  {
    path: '/parts/:id',
    element: <UpdatePart />
  },
  {
    path: '/parts/bulkUpdate',
    element: <BulkUpdate />
  },
  {
    path: '/parts/movements',
    element: <PartMovementList />
  },
  {
    path: '/parts/tlc',
    element: <TLC />
  },
  {
    path: '/configure/replenishment/config',
    element: <ReplenishmentConfigs />
  },
  {
    path: '/configure/sku/rules',
    element: <SKURuleList />
  },
  {
    path: '/shipments',
    element: <ShipmentList />
  },
  {
    path: '/shipments/stock/:id',
    element: <AddEditStock />
  },
  {
    path: '/shipments/dispatch/:id',
    element: <Dispatch />
  },
  {
    path: '/shipments/return/:id',
    element: <Return />
  },
  {
    path: '/shipments/:id',
    element: <EditShipment />
  },
  {
    path: '/reports/attendance',
    element: <AttendanceReportList />
  },
  {
    path: '/reports/replenishment',
    element: <ReplenishmentReportList />
  },
  {
    path: '/reports/myreports',
    element: <MyReportsList />
  },
  {
    path: '/shipments/receive',
    element: <IndividualShipmentReceive />
  },
  {
    path: '/shipments/receive/:id',
    element: <IndividualShipmentReceive />
  },
  {
    path: '/configure/interactions/templates',
    element: <InteractionsTemplate/>
  },
  {
    path: '/configure/interactions/rules',
    element: <InteractionsRules/>
  },
  {
    path: '/configure/notifications/rules',
    element: <NotificationRules />
  },
  {
    path: '/configure/notifications/templates',
    element: <NotificationTemplates />
  },  
  {
    path: '/shipments/batchReceive',
    element: <BatchReceiveList />
  },
  {
    path: '/shipments/batchReceive/:id',
    element: <AddEditBatchReceive />
  },
  {
    path: '/parts/tlcTemplates',
    element: <TLCTemplate/>
  },
  {
    path: '/parts/tlcTemplates/:id',
    element: <AddEditTemplate/>
  },
  {
    path: '/loader',
    element: <NavigatorSupport/>
  }
);

export const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: routes
};