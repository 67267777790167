import { EMPTY_FIELD_MESSAGE } from '../constants/common';
import { CONTAIN_ONLY_ALPHABETICS_NUMBERS_AND_CHARACTERS_MESSAGE } from '../constants/user.constant';
import { isEmptyString } from './common';

export const containValidCharacters = (value: string) => {
  if (/^[a-zA-Z0-9!@#$&-]+$/.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const nameValidation = (value: string, text: string) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else if (containValidCharacters(value)) {
    return CONTAIN_ONLY_ALPHABETICS_NUMBERS_AND_CHARACTERS_MESSAGE + text;
  } else {
    return '';
  }
};