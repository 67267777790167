import { SizeProps } from './../../../@types/common.type';

export enum CheckboxColorProps {
  Default = 'default',
  Secondary = 'secondary',
  Primary = 'primary',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export interface CheckboxProps {
  checked?: boolean;
  onChange?: (event: any) => void;
  color?: CheckboxColorProps;
  indeterminate?: boolean;
  size?: SizeProps;
  disabled?: boolean;
  testDataId?: string;
}