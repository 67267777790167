import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import { SizeProps } from '../../../@types/common.type';
import { TextFieldTypeProps } from '../../atoms/TextField';
import { ManifestSerialProps } from './ManifestSerial.props';
import { StyledScrollableWrapper, StyledTextField } from './ManifestSerial.styles';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { SelectMenu } from '../../atoms/SelectMenu';
import { DataGrid } from '../../atoms/DataGrid';
import { ManifestSerialItem, ManifestSerialObject, SerialPopupObject } from '../../../@types/shipment.type';
import { useDeleteSerializedManifestById } from '../../../queries/shipment-query';

export const ManifestSerial: React.FC<ManifestSerialProps> = ({
  manifestedData,
  serialPopupItems,
  isEdit,
  isPending,
  validate,
  onChange,
  onKeyDown
}) => {
  const [manifestedValue, setManifestedValue] = useState<ManifestSerialObject>(manifestedData);
  const [serials, setSerials] = useState<Array<ManifestSerialItem>>();
  const [serialPopupValues, setSerialPopupValues] = useState<SerialPopupObject>(serialPopupItems);

  const deleteSerializedManifestById = useDeleteSerializedManifestById();
  
  const columns: GridColDef[] = [
    { field: 'serial1', headerName: 'Serial1', flex: 1 },
    { field: 'serial2', headerName: 'Serial2', flex: 1 },
    { field: 'serial3', headerName: 'Serial3', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      renderCell: (params: GridRenderCellParams) => 
        !isPending && <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(params.row.id)}
          color="inherit"
        />,
    }
  ];

  useEffect(() => {
    manifestedData && setManifestedValue(manifestedData);
    manifestedData && setSerials(manifestedData?.serialsDataSet);
  }, [manifestedData]);

  useEffect(() => {
    serialPopupItems && setSerialPopupValues(serialPopupItems);
  }, [serialPopupItems]);
  
  const onSelectionModelChange = (selectedRows: GridSelectionModel) => {
    const filteredSerials = selectedRows.reduce(
      (result: Array<ManifestSerialItem>, id) => {
        const item = manifestedValue?.serialsDataSet?.find(
          (item: { id?: number }) => item.id === id
        );
        if (item) {
          result.push({
            serial1: item?.serial1 || '',
            serial2: item?.serial2 || '',
            serial3: item?.serial3 || ''
          });
        }
        return result;
      },
      []
    );

    onChange({ ...manifestedValue, filteredSerials: filteredSerials });
  };

  const handleDeleteClick = (id: number) => () => {
    const filteredSerials = serials?.filter((serial) => serial.id !== id);
    setSerials(filteredSerials);
    onChange({ ...manifestedValue, serialsDataSet: filteredSerials });
    isEdit && deleteSerializedManifestById.mutateAsync(id);
  };
  
  return (
    <>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-condition"
            labelId="demo-simple-select-condition-label"
            label="Condition *"
            selectedValue={manifestedValue.condition || '-1'}
            onChange={(val) => onChange({ ...manifestedValue, condition: val })}
            items={serialPopupValues?.conditionOptions}
            validate={validate}
            optionalLabelEnabled={true}
          />
        </Grid>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-software"
            labelId="demo-simple-select-software-label"
            label="Software"
            selectedValue={manifestedValue.softwareVersion || '-1'}
            onChange={(val) => onChange({ ...manifestedValue, softwareVersion: val })}
            items={serialPopupValues.sfhOptions?.softwares}
            optionalLabelEnabled={true}
          />
        </Grid>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-hardware"
            labelId="demo-simple-select-hardware-label"
            label="Hardware"
            selectedValue={manifestedValue.hardwareRevision || '-1'}
            onChange={(val) => onChange({ ...manifestedValue, hardwareRevision: val })}
            items={serialPopupValues.sfhOptions?.hardwares}
            optionalLabelEnabled={true}
          />
        </Grid>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-firmware"
            labelId="demo-simple-select-firmware-label"
            label="Firmware"
            selectedValue={manifestedValue.firmwareVersion || '-1'}
            onChange={(val) => onChange({ ...manifestedValue, firmwareVersion: val })}
            items={serialPopupValues.sfhOptions?.firmwares}
            optionalLabelEnabled={true}
          />
        </Grid>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-fleet-tag"
            labelId="demo-simple-select-fleet-tag-label"
            label="Fleet Tag"
            selectedValue={manifestedValue.fleetTag || '-1'}
            onChange={(val) => onChange({ ...manifestedValue, fleetTag: val })}
            items={serialPopupValues?.fleetTagOptions}
            optionalLabelEnabled={true}
          />
        </Grid>
      </Grid>
      <StyledTextField
        disabled={isPending}
        label="Smart Input"
        multiline
        type={TextFieldTypeProps.String}
        fullWidth
        onChange={(val: string) => onChange({ ...manifestedValue, smartInput: val })}
        onKeyDown={onKeyDown}
      />
      {serials && serials?.length > 0 && (
        <Stack direction="row" spacing={6} mt={4} justifyContent="center" alignItems="flex-start">
          <StyledScrollableWrapper>
            <DataGrid
              columns={columns}
              rows={serials.map((item, index) => ({ id: index + 1, ...item }))}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              onSelectionModelChange={onSelectionModelChange}
            />
          </StyledScrollableWrapper>
        </Stack>
      )}
    </>
  );
};