import { SetStateAction } from 'react';
import { TradingHoursData } from '../../molecules/TradingHours';

export enum FieldName {
  SERVICETYPES = 'serviceType',
  PRIORITIES = 'priorities',
  REGIONTYPES = 'regionTypes',
  TRADINGHOURS = 'tradingHours',
  SWITCH = 'switch'
}

export interface MultiSelectDropdownProps {
  value: string;
  label: string;
  serviceTypes?: string[];
}

export interface CoverageValuesProps {
  selectedServiceTypes: string[];
  selectedPriorities: string[];
  selectedRegionTypes: string[];
  tradingHours: TradingHoursData;
  checked: boolean;
}

export interface CoverageProps {
  serviceTypeItems: MultiSelectDropdownProps[];
  prioritiesItems: MultiSelectDropdownProps[];
  regionTypeItems: MultiSelectDropdownProps[];
  values: CoverageValuesProps;
  formDataIndex?: number;
  onChange?: ((value: SetStateAction<CoverageValuesProps>) => void);
  onDelete?: (() => void);
  onClick?: (event: any) => void;
}
