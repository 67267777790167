import { CustomDatePickerFilter } from '../components/atoms/CustomDatePickerFilter';

export const EMPTY_FIELD_MESSAGE = ' cannot be empty';
export const NAME_FIELD_HELPER_TEXT = 'Name field';
export const SHORT_NAME_FIELD_HELPER_TEXT = 'Short Name field';
export const CODE_FIELD_HELPER_TEXT = 'Code field';
export const LARGER_THAN_MESSAGE = ' cannot be larger than ';
export const SMALLER_THAN_MESSAGE = ' cannot be smaller than ';
export const ANY_KEYWORD = 'any';
export const EMPTY_NAME_FIELD_MESSAGE = ' Name is required ';

export const TAB_LABEL = 'Tab';
export const TAB = '\t';
export const ENTER_LABEL = 'Enter';

export const ANY_ITEM = [{ value: '0', label: 'any' }];
export const NONE_ITEM = [{ value: '0', label: 'none' }];
export const ANY_ITEM_ARRAY = [{ value: 'any', label: 'Any' }];
export const SAMPLE_DROPDOWN_ITEM_ARRAY = [
  { value: 'one', label: 'One' },
  { value: 'two', label: 'Two' },
  { value: 'three', label: 'Three' }
];
export const OTHER_ITEM_ARRAY = { value: 'other', label: 'Other', id: 0 };

export const ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' }
];

export const ALL_KEYWORD = 'all';
export const ALL_KEYWORD_CAPITAL = 'All';
export const RICH_TEXT_EDITOR_EMPTY_TEXT = '<p><br></p>';

export const SUCCESS_MESSAGE_VALUE_TYPE = {
  isTrue: false,
  message: ''
};

export const UNDEFINED_TREE = 'undefined/undefined';
export const UNDEFINED_TREE_FIRST_ITEM = 'undefined/';
export const UNDEFINED_TREE_UPTO_THREE = 'undefined//';

export const CUSTOM_DATE_FILTER_OPERATORS = [
  {
    label: 'is',
    value: 'is',
    InputComponent: CustomDatePickerFilter,
    getApplyFilterFn: () => null
  },
  {
    label: 'is not',
    value: 'not',
    InputComponent: CustomDatePickerFilter,
    getApplyFilterFn: () => null
  },
  {
    label: 'is after',
    value: 'after',
    InputComponent: CustomDatePickerFilter,
    getApplyFilterFn: () => null
  },
  {
    label: 'is on or after',
    value: 'onOrAfter',
    InputComponent: CustomDatePickerFilter,
    getApplyFilterFn: () => null
  },
  {
    label: 'is before',
    value: 'before',
    InputComponent: CustomDatePickerFilter,
    getApplyFilterFn: () => null
  },
  {
    label: 'is on or before',
    value: 'onOrBefore',
    InputComponent: CustomDatePickerFilter,
    getApplyFilterFn: () => null
  },
  {
    label: 'is empty',
    value: 'isEmpty',
    getApplyFilterFn: () => null
  },
  {
    label: 'is not empty',
    value: 'isNotEmpty',
    getApplyFilterFn: () => null
  }
];