import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { ScheduleType } from '../configs/enums';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const SAMPLE_DATE_RANGE: DateRange<Dayjs> = [dayjs().startOf('week'), dayjs().endOf('week')];

export const GRID_MENU_REPORT_OPTIONS1 = ['Column Selection', 'Save Report', 'Set as Default Report'];

export const GRID_MENU_REPORT_OPTIONS2 = ['Save Report', 'Set as Default Report'];

export const GRID_MENU_REPORT_OPTIONS3 = ['Column Selection', 'Save Report'];

export const GRID_MENU_REPORT_OPTIONS4 = ['Save Report'];

export const GRID_MENU_REPORT_OPTIONS5 = ['Column Selection'];

export const CONFIRMATION_MESSAGES = {
  removeDefaultMode: 'Do you want to remove this report from default mode ?',
  setAsDefault: 'Do you want to set this report as the default ?',
  alreadyExist: 'Default report already exists. Do you want to replace it ?'
};

export const REPORT_ACCESS_DATA = {
  enableEdit: false,
  enableColumnSelection: false,
  enableFiltering: false
};

export enum GridMenuOption {
  ColumnSelection = 'Column Selection',
  SaveReport = 'Save Report',
  SetAsDefault = 'Set as Default Report'
}

export const SCHEDULE_TYPE: Array<DropdownItem> = [
  {
    value: ScheduleType.Daily,
    label: 'Daily (midnight)'
  },
  {
    value: ScheduleType.Weekly,
    label: 'Weekly (midnight on Sunday)'
  },
  {
    value: ScheduleType.Monthly,
    label: 'Monthly (1st of month)'
  },
  {
    value: ScheduleType.Yearly,
    label: 'Yearly (Jan 1st)'
  },
  {
    value: ScheduleType.Custom,
    label: 'Custom'
  }
];

export const columnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'My Reports',
    value: [
      {
        field: 'name',
        title: 'Report Name',
        value: true
      },
      {
        field: 'pageName',
        title: 'Page Name',
        value: true
      },
      {
        field: 'reportOwner',
        title: 'Report Owner',
        value: true
      },
      {
        field: 'createdAt',
        title: 'Created At',
        value: true
      },
      {
        field: 'sharedWith',
        title: 'Shared With',
        value: false
      },
      {
        field: 'scheduledDetails',
        title: 'Scheduled Details',
        value: false
      },
      {
        field: 'edit', 
        title: 'Edit', 
        value: true
      },
      {
        field: 'delete', 
        title: 'Delete', 
        value: true
      },
    ]
  }
];