import React, { useEffect, useState, KeyboardEvent } from 'react';
import {
  GridDensityTypes,
  LicenseInfo,
  GridLinkOperator,
  GridToolbar,
  GridColumnOrderChangeParams,
  GridFilterPanel,
  GridFilterModel,
  useGridApiContext,
  GridColDef
} from '@mui/x-data-grid-pro';
import { DataGridProps } from './DataGrid.props';
import { FilterWithSearchButtonWrapper, FilterWithSearchWrapper, GridFlexGrowWrapper, GridFlexWrapper, StyledDataGrid } from './DataGrid.styles';
import { Button, ButtonColorProps, ButtonVariantProps } from '../Button';
import { checkListPagetFilterAvailability } from '../../../utils/common';
import { CUSTOM_DATE_FILTER_OPERATORS } from '../../../constants/common';

LicenseInfo.setLicenseKey('10a4ce6ba93bfc717b294e06813b9d34Tz01NjM1OSxFPTE3MDMwNDgwMTUzMjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// replace emptly element with DataGrid footer if footer disabled
export function CustomFooterStatusComponent() {
  return (
    <></>
  );
}

export const DataGrid: React.FC<DataGridProps> = ({
  columns,
  rows,
  density = GridDensityTypes.Compact,
  getRowId,
  componentsProps,
  columnVisibilityModel,
  disableColumnFilter,
  disableColumnMenu,
  disableColumnSelector,
  disableDensitySelector,
  checkboxSelection,
  getDetailPanelHeight,
  getDetailPanelContent,
  filterMode,
  onFilterModelChange,
  loading,
  rowCount,
  rowsPerPageOptions,
  pagination,
  page,
  pageSize,
  paginationMode,
  onPageChange,
  onPageSizeChange,
  customToolbar,
  filterModel,
  onSortModelChange,
  sortingMode,
  getRowClassName,
  selectionModel,
  onSelectionModelChange,
  hideFooter,
  autoHeight = false,
  treeData,
  getTreeDataPath,
  defaultGroupingExpansionDepth,
  groupingColDef,
  initialState,
  getRowHeight,
  isRowSelectable,
  setColumns,
  customFooter,
  onCellClick,
  onCellEditStop,
  apiRef,
  experimentalFeatures,
  getCellClassName,
  showCellRightBorder,
  headerHeight,
  onCellDoubleClick,
  isGroupExpandedByDefault,
  serviceColumns,
  disableSelectionOnClick,
  hasFilterPanelWithSearch = true,
  filtersSearch
}) => {
  const [updatedColumns, setUpdatedColumns] = useState<GridColDef[]>([]);
  const [customFilterModel, setCustomFilterModel] = useState<GridFilterModel>({ items: [] });
  const [enterKeySearch, setEnterKeySearch] = useState(false);

  useEffect(() => {
    setUpdatedColumns(customizeDateColumns(columns));
  }, [columns]);

  // For report filters or default filters in list pages
  useEffect(() => {
    hasFilterPanelWithSearch && filterModel && filterModel?.items.length !== 0 && setCustomFilterModel(filterModel);
  }, [filterModel]);

  const FilterPanelWithSearch = (props: any) => {
    const [isNotEnter, setIsNotEnter] = useState(false);
    const apiRef = useGridApiContext();
    
    const handleSearch = () => {
      onFilterModelChange && onFilterModelChange(customFilterModel as GridFilterModel,{});
      filtersSearch && filtersSearch(); // To refetch list query when Search is clicked with no filter changes
      apiRef.current.hideFilterPanel(); // Hide filter panel when Search is clicked
    };

    useEffect(() => {
      if (enterKeySearch && customFilterModel.items.every((filter) => filter.operatorValue !== 'isAnyOf')) {
        handleSearch();
        setEnterKeySearch(false);
      }
    }, [customFilterModel]);

    return (
      <FilterWithSearchWrapper>
        <GridFilterPanel
          {...props}
          key="custom-filter-panel"
          linkOperators={[GridLinkOperator.And]}
          columnsSort="asc"
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
              if (!isNotEnter && customFilterModel.items.length > 0 && checkListPagetFilterAvailability(customFilterModel as GridFilterModel)) {
                handleSearch();
              } else {
                setEnterKeySearch(true);
              }
              setIsNotEnter(false);
            } else {
              !isNotEnter && setIsNotEnter(true);
            }
          }}
          filterFormProps={{
            // Customize inputs by passing props
            linkOperatorInputProps: {
              sx: { display: 'none' }
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
              sx: { width: 'auto', minWidth: '200px' }
            },
            deleteIconProps: {
              sx: {
                '& .MuiSvgIcon-root': { color: '#d32f2f' },
              },
            }
          }}
        />
        <FilterWithSearchButtonWrapper>
          <Button
            variant={ButtonVariantProps.Secondary}
            color={ButtonColorProps.Primary}
            disabled={!checkListPagetFilterAvailability(customFilterModel as GridFilterModel) || customFilterModel.items.length === 0}
            onClick={handleSearch}
          >
            Search
          </Button>
        </FilterWithSearchButtonWrapper>
      </FilterWithSearchWrapper>
    );
  };

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    if (hasFilterPanelWithSearch) {
      setCustomFilterModel(filterModel);
    } else {
      onFilterModelChange && onFilterModelChange(filterModel, {});
    }
  };

  const customizeDateColumns = (columns: GridColDef[]) => {
    const updatedColumns = columns.map((column) => column.type === 'date' ? { ...column, filterOperators: CUSTOM_DATE_FILTER_OPERATORS } : column);
    return updatedColumns;
  };

  return (
    <GridFlexWrapper>
      <GridFlexGrowWrapper>
        <StyledDataGrid
          columns={updatedColumns}
          rows={rows}
          density={density}
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          disableColumnFilter={disableColumnFilter}
          disableColumnMenu={disableColumnMenu}
          disableColumnSelector={disableColumnSelector}
          disableDensitySelector={disableDensitySelector}
          disableSelectionOnClick={disableSelectionOnClick}
          checkboxSelection={checkboxSelection}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          initialState={initialState}
          componentsProps={{
            ...componentsProps,
            filterPanel: {
              // Force usage of "And" operator
              linkOperators: [GridLinkOperator.And],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              filterFormProps: {
                // Customize inputs by passing props
                linkOperatorInputProps: {
                  sx: { display: 'none' }
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                  sx: { width: 'auto', minWidth: '200px' }
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                  },
                },
              }
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          filterMode={filterMode}
          onFilterModelChange={handleFilterModelChange}
          loading={loading}
          rowCount={rowCount}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination={pagination}
          page={page}
          pageSize={pageSize}
          paginationMode={paginationMode}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          sortingMode={sortingMode}
          components={{
            Toolbar: customToolbar || GridToolbar,
            ...(hideFooter && { Footer: customFooter || CustomFooterStatusComponent }),
            ...(hasFilterPanelWithSearch && { FilterPanel: FilterPanelWithSearch })
          }}
          filterModel={hasFilterPanelWithSearch ? customFilterModel : filterModel}
          getRowClassName={getRowClassName}
          selectionModel={selectionModel}
          onSelectionModelChange={onSelectionModelChange}
          autoHeight={autoHeight}
          treeData={treeData}
          getTreeDataPath={getTreeDataPath}
          defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
          groupingColDef={groupingColDef}
          isRowSelectable={isRowSelectable}
          disableVirtualization={true}
          onColumnOrderChange={(params: GridColumnOrderChangeParams) => {
            const { targetIndex, oldIndex } = params;
            const columnsSet = serviceColumns || columns;
            const updatedColumns = [...columnsSet];
        
            // Remove the column from the old index
            const [movedColumn] = updatedColumns.splice(oldIndex, 1);
        
            // Insert the column at the target index
            updatedColumns.splice(targetIndex, 0, movedColumn);

            setColumns && setColumns(updatedColumns);
          }}
          onCellClick={onCellClick}
          onCellEditStop={onCellEditStop}
          apiRef={apiRef}
          experimentalFeatures={experimentalFeatures}
          getCellClassName={getCellClassName}
          showCellRightBorder={showCellRightBorder}
          headerHeight={headerHeight || 56}
          onCellDoubleClick={onCellDoubleClick}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
        />
      </GridFlexGrowWrapper>
    </GridFlexWrapper>
  );
};