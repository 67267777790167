import React from 'react';
import { Grid } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { MainPartEditProps } from './MainPartEdit.props';
import { EditNodePartLiveSearch } from '../../organisms/EditNodePartLiveSearch/EditNodePartLiveSearch';
import { StyledLabel, StyledSelectMenu } from './MainPartEdit.styles';
import { SizeProps, Tag } from '../../../@types';
import { TypographyVariantProps } from '../../atoms/Typography/Typography.props';
import { EditPartTypeLiveSearch } from '../../organisms/PartTypeSearchBox/EditPartTypeLiveSearch';
import { Tags } from '../../organisms/Tags/Tags';
import { AutocompleteTagType } from '../../organisms/Tags';
import { getFleetTagDropDownSelectedLabel, getPartConditionSelectedLabel, getSFHDropDownSelectedLabel } from '../../../utils/part';
import { TextField } from '../../atoms/TextField';

export const MainPartEdit: React.FC<MainPartEditProps> = ({
  permissions,
  value,
  partType,
  onChange,
  allTags,
  setNewTags,
  setSelectedTags,
  selectedTags,
  newTags,
  conditions,
  sfhDetails,
  partLocation,
  handlePartTypeChange,
  handlePartLocationChange,
  validate
}) => {
  const onChangeNewTags = (tag: Tag[]) => {
    setNewTags(tag);
  };

  const onChangeSelectedTags = (selectedTag: (string | AutocompleteTagType)[]) => {
    setSelectedTags(selectedTag);
  };

  return (
    <Grid container spacing={2} mt={2} pl={2}>
      <Grid item container xs={12} md={4}>
        <Grid item container>
          <Grid item xs={12} md={4}>
            <Typography>Location</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <EditNodePartLiveSearch
              isLocation={permissions.isLocation}
              isClientHierarchy={permissions.isClientHierarchy}
              partLocation={partLocation}
              isEditable={true}
              handlePartLocationChange={handlePartLocationChange}
            />
          </Grid>
        </Grid>
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Conditions</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <StyledSelectMenu
              disabled={!permissions.isCondition}
              required={false}
              size={SizeProps.Small}
              id="part-edit-condition"
              labelId="part-edit-condition-label"
              label="Condition *"
              selectedValue={value.conditionCode}
              onChange={(condition) => onChange({ ...value, conditionCode: condition, conditionName: getPartConditionSelectedLabel(condition, conditions) })}
              items={conditions}
              validate={validate}
              optionalLabelEnabled={true}
            />
          </Grid>
        </Grid>
        {value.partConditionHistory?.serviceId && (
          <Grid item container>
            <Grid item xs={12} md={4}>
              <div></div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">Service ID: {value.partConditionHistory.serviceId}</Typography>
            </Grid>
          </Grid>
        )}
        {value.partConditionHistory?.lastLocation && (
          <Grid item container>
            <Grid item xs={12} sm={6} md={4}>
              <div></div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">Last Location: {value.partConditionHistory.lastLocation}</Typography>
            </Grid>
          </Grid>
        )}
        {value.partConditionHistory?.lastCondition && (
          <Grid item container>
            <Grid item xs={12} md={4}>
              <div></div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">Last Condition: {value.partConditionHistory.lastCondition}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Notes</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              size={SizeProps.Small}
              label="Notes"
              multiline
              rows={6}
              value={value.notes}
              onChange={(note) => onChange({ ...value, notes: note })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={4}>
        <Grid item container>
          <Grid xs={12} md={4}>
            <Typography>Part&nbsp;Type</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <EditPartTypeLiveSearch
              partData={value}
              partTypeData={partType}
              isEditable={permissions.isPartType}
              handlePartTypeChange={handlePartTypeChange}
            />
          </Grid>
        </Grid>
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Serial&nbsp;1</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              disabled={!permissions.isSerial}
              fullWidth
              size={SizeProps.Small}
              label="Serial1"
              value={value.serial1}
              onChange={(serial1) => onChange({ ...value, serial1 })}
            />
          </Grid>
        </Grid>
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Serial&nbsp;2</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              disabled={!permissions.isSerial}
              fullWidth
              size={SizeProps.Small}
              label="Serial2"
              value={value.serial2}
              onChange={(serial2) => onChange({ ...value, serial2 })}
            />
          </Grid>
        </Grid>
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Serial&nbsp;3</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              disabled={!permissions.isSerial}
              fullWidth
              size={SizeProps.Small}
              label="Serial3"
              value={value.serial3}
              onChange={(serial3) => onChange({ ...value, serial3 })}
            />
          </Grid>
        </Grid>
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Tags</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Tags
              selectedTags={selectedTags}
              newTags={newTags}
              setSeletedTags={onChangeSelectedTags}
              setNewTags={onChangeNewTags}
              allTags={allTags}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={4}>
        {sfhDetails?.softwares && sfhDetails?.softwares.length > 0 && (
          <Grid item container>
            <Grid item xs={12} md={4}>
              <StyledLabel>Software</StyledLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledSelectMenu
                required={false}
                size={SizeProps.Small}
                id="part-edit-software"
                labelId="part-edit-software-label"
                label="Software"
                selectedValue={value.softwareVersionId || '-1'}
                onChange={(softwareVersionId) => onChange({ ...value, softwareVersionId, softwareVersion: getSFHDropDownSelectedLabel(softwareVersionId, sfhDetails.softwares) })}
                items={sfhDetails.softwares}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>
        )}
        {sfhDetails?.hardwares && sfhDetails?.hardwares.length > 0 && (
          <Grid item container mt={{ xs: 1 }}>
            <Grid item xs={12} md={4}>
              <StyledLabel>Hardware</StyledLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledSelectMenu
                required={false}
                size={SizeProps.Small}
                id="part-edit-hardware"
                labelId="part-edit-hardware-label"
                label="Hardware"
                selectedValue={value.hardwareRevisionId || '-1'}
                onChange={(hardwareRevisionId) => onChange({ ...value, hardwareRevisionId, hardwareRevision: getSFHDropDownSelectedLabel(hardwareRevisionId, sfhDetails.hardwares) })}
                items={sfhDetails.hardwares}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>
        )}
        {sfhDetails?.firmwares && sfhDetails?.firmwares.length > 0 && (
          <Grid item container mt={{ xs: 1 }}>
            <Grid item xs={12} md={4}>
              <StyledLabel>Firmware</StyledLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledSelectMenu
                required={false}
                size={SizeProps.Small}
                id="part-edit-firmware"
                labelId="part-edit-firmware-label"
                label="Firmware"
                selectedValue={value.firmwareVersionId || '-1'}
                onChange={(firmwareVersionId) => onChange({ ...value, firmwareVersionId, firmwareVersion: getSFHDropDownSelectedLabel(firmwareVersionId, sfhDetails.firmwares) })}
                items={sfhDetails.firmwares}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>
        )}
        {value?.fleetTag && value?.fleetTag?.length > 0 && (
          <Grid item container mt={{ xs: 1 }}>
            <Grid item xs={12} md={4}>
              <StyledLabel>Fleet Tag</StyledLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledSelectMenu
                required={false}
                size={SizeProps.Small}
                id="part-edit-fleet-tag"
                labelId="part-edit-fleet-tag-label"
                label="Fleet Tag"
                selectedValue={value.fleetTagId || '-1'}
                onChange={(fleetTagId) => onChange({ ...value, fleetTagId, fleetTagName: getFleetTagDropDownSelectedLabel(fleetTagId, value.fleetTag) })}
                items={value.fleetTag || []}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>
        )}
        <Grid item container mt={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <Typography>Config Change Notes</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              multiline
              size={SizeProps.Small}
              rows={4}
              label="Config Change Notes"
              value={value.configChnageNotes}
              onChange={(configChnageNote) => onChange({ ...value, configChnageNotes: configChnageNote })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};