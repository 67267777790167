import React, { useEffect, useState } from 'react';
import { CardActions, Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { ImagePopup, ImagePopupSlide } from '../../../../../components/atoms/ImagePopup';
import { PartTypeActionSearch } from '../../../../../components/molecules/PartTypeActionSearch';
import { PartTypeActionSHFData } from '../../../../../components/molecules/PartTypeActionSHFData';
import { PartTypeActionNote } from '../../../../../components/molecules/PartTypeActionNote';
import { useGetUploadedImages } from '../../../../../queries/uploadedfiles-query';
import { useGetPartsLogAgainstService } from '../../../../../queries/part-query';
import { ActionPermissions, CustomPermission, PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../../../@types';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { isEmptyString } from '../../../../../utils/common';
import { PartActionOtherCardProps } from './PartActionOtherCard.props';
import { 
  DefaultCard, 
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  SHFDataWrapper,
  StyledButton
} from './PartActionOtherCard.styles';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { SerailizedPartsList } from '../../../../../@types/part.type';
import { PartLocationTypeCode, PartTypeCategory, SerializedDropDownOtherOption } from '../../../../../configs/enums';
import { COLORS } from '../../../../../configs/colors';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { isUserHasPermission } from '../../../../../configs/permissions';

export const PartActionOtherCard: React.FC<PartActionOtherCardProps> = ({
  service,
  value,
  helperText,
  title,
  onChange,
  onSave,
  onExec,
  onClose,
  setHelperText
}) => {
  const [isHavingPermission, setIsHavingPermission] = useState(true);
  const [isSearchMode, setIsSearchMode] = useState(true);
  const [isSerialized, setIsSerialized] = useState(false);
  const [showSerialSearch, setShowSerialSearch] = useState(false);
  const [partImages, setPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [serializedParts, setSerializedParts] = useState<SerailizedPartsList[]>([]);
  const [isPartTypeActionSearch, setIsPartTypeActionSearch] = useState<boolean>(false);
  const [isSerialDropDown, setIsSerialDropDown] = useState<boolean>(true);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const uploadedImagesQuery = useGetUploadedImages(value.partTypeId || 0, Folders.PartTypesImages);
  const getPartsLogAgainstServiceQuery = useGetPartsLogAgainstService(service.id, PartLocationTypeCode.Node);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    setIsSearchMode(isEmptyString(value.name));
    setIsSerialized(value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    setShowSerialSearch(value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    setIsPartTypeActionSearch(value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice || value.category === PartTypeActionLiveSearchItemCategoryCode.NonSerialisedComponent);
    value.partTypeId && uploadedImagesQuery.refetch();
  }, [value.name, value.category, value.partTypeId]);

  useEffect(() => {
    if (uploadedImagesQuery.data) {
      const images: Array<ImagePopupSlide> = uploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setPartImages(images);
    }
  }, [uploadedImagesQuery.data]);

  useEffect(() => {
    if (getPartsLogAgainstServiceQuery?.data) {
      const serializedPartItems = getPartsLogAgainstServiceQuery?.data || [];
      setSerializedParts(serializedPartItems);
    }
  }, [getPartsLogAgainstServiceQuery?.data]);

  useEffect(() => {
    setIsHavingPermission(isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions));
  }, [permissions]);

  const handlePartTypeActionSearch = async (liveSearchSelectedItem: PartTypeActionLiveSearchItem) => {
    setHelperText({
      ...helperText,
      in: { 
        ...helperText.in,
        partTypeHelperText: '',
        partSerialHelperText: ''
      },
    });

    if (liveSearchSelectedItem.serial1) {
      onChange({
        outValue: value,
        inValue: value,
        value:{
          ...value,
          id: value.id,
          partTypeId: liveSearchSelectedItem.partTypeId,
          partId: liveSearchSelectedItem.partId,
          name: liveSearchSelectedItem.partTypeName,
          category: liveSearchSelectedItem.categoryCode,
          condition: liveSearchSelectedItem.conditionCode,
          conditionName: liveSearchSelectedItem?.partCondition?.name,
          serial: liveSearchSelectedItem.serial1,
          location: liveSearchSelectedItem.locationName,
          sw: liveSearchSelectedItem.softwareVersion,
          hw: liveSearchSelectedItem.hardwareVersion,
          fw: liveSearchSelectedItem.firmwareVersion,
          ft: liveSearchSelectedItem.fleetTagName
        }
        
      });
    } else {
      onChange({
        outValue: value,
        inValue: value,
        value:{
          ...value,
          id: value.id,
          partTypeId: liveSearchSelectedItem.partTypeId,
          ns_partId: liveSearchSelectedItem.partId,
          name: liveSearchSelectedItem.partTypeName,
          category: liveSearchSelectedItem.categoryCode,
          condition: liveSearchSelectedItem.conditionCode,
          conditionName: liveSearchSelectedItem?.partCondition?.name,
          location: liveSearchSelectedItem.locationName,
          serial: '',
          sw: liveSearchSelectedItem.softwareVersion,
          hw: liveSearchSelectedItem.hardwareVersion,
          fw: liveSearchSelectedItem.firmwareVersion,
          ft: liveSearchSelectedItem.fleetTagName
        }       
      });
    }
  };


  const handlePartTypeSerialSearch = async (partData: PartTypeActionLiveSearchItem) => {
    setHelperText({
      ...helperText,
      in: { 
        ...helperText.in,
        partTypeHelperText: '',
        partSerialHelperText: ''
      },
    });

    onChange({
      outValue: value,
      inValue: value,
      value:{
        ...value,
        ns_partId: NaN,
        partId: partData.partId,
        serial: partData.serial1,
        location: partData.locationName,
        condition: partData.conditionCode,
        conditionName: partData?.partCondition?.name,
        sw: partData.softwareVersion,
        hw: partData.hardwareVersion,
        fw: partData.firmwareVersion,
        ft: partData.fleetTagName
      }
    });
  };

  const handleSerializedPartSelection = (val: string) => {
    if (val === SerializedDropDownOtherOption.Other) {
      setIsPartTypeActionSearch(true);
      setIsSerialDropDown(false);
    } else {
      const result = serializedParts.filter((part) => part.value === val);
      onChange({
        outValue: value,
        inValue: value,
        value:{
          ...value,
          id: value.id,
          name: result[0].name,
          partTypeId: result[0].partTypeId,
          partId: result[0].partId,
          category: result[0].category,
          condition: result[0].condition,
          conditionName: result[0].conditionName,
          serial: result[0].serial,
          location: result[0].location,
          sw: result[0].sw,
          hw: result[0].hw,
          fw: result[0].fw,
          ft: result[0].ft
        }
      });
      setIsSerialDropDown(false);
    }
  };
  
  return (
    <DefaultCard>
      <StyledCardContent>
        <Stack sx={{ backgroundColor: COLORS.White }}>
          <CardTitle>{title}</CardTitle>
        </Stack>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} marginTop={1} width="100%">
            {isSerialDropDown && !value.partTypeId &&
              <SelectMenu id="serialized-parts"
                label="Serialized Parts" 
                items={serializedParts} 
                onChange={(value) => handleSerializedPartSelection(value)}
              />
            }
            <Stack direction="column" spacing={2}width="100%">
              {(isPartTypeActionSearch || value.actionId) &&
                <PartTypeActionSearch
                  serviceData={service}
                  locationId={service.subLocationNodeId || service.mainLocationNodeId}
                  serialiseType={PartTypeCategory.NonSerialisedComponent}
                  title="Part Type Search"
                  value={value.name || ''}
                  error={!!helperText.in.partTypeHelperText}
                  helperText={helperText.in.partTypeHelperText}
                  onChange={handlePartTypeActionSearch}
                  searchType={showSerialSearch || value.actionId && value.category !== PartTypeCategory.NonSerialisedComponent ? 'part-type' : 'part-and-part-type'}
                />
              }
              {(showSerialSearch || value.actionId && value.category !== PartTypeCategory.NonSerialisedComponent) &&
                <PartTypeActionSearch
                  title="Part Serial Search"
                  serialiseType={value.category}
                  partTypeId={`${value.id}`}
                  serviceData={service}
                  value={value.serial || ''}
                  error={!!helperText.in.partSerialHelperText}
                  helperText={helperText.in.partSerialHelperText}
                  onChange={handlePartTypeSerialSearch}
                  searchType="part"
                />
              }
            </Stack>
            {isSearchMode || <ImagePopup title="Photos" images={partImages} />}
          </Stack>
          {isSearchMode || 
            <>
              {isSerialized &&
                <SHFDataWrapper>
                  <PartTypeActionSHFData sw={value.sw || '_'} hw={value.hw || '_'} fw={value.fw || '_'} />
                </SHFDataWrapper>
              }
              {value.condition && 
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    COND:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.conditionName || value.condition}</Typography>
                </Stack>
              }
              {value.location &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    LOC:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.location}</Typography>
                </Stack>
              }
              {value.ft &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    FT:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.ft}</Typography>
                </Stack>
              }
              <Grid item xs={12}>
                <PartTypeActionNote
                  value={value?.notes || ''}
                  onChange={(note) => onChange({
                    outValue: value,
                    inValue: value,
                    value:{
                      ...value,
                      notes: note
                    }       
                  })} 
                />
              </Grid>
            </>
          }
        </Stack>
      </StyledCardContent>
      {isHavingPermission && ( isSearchMode ||
        <CardActions>
          <Stack direction="row-reverse" spacing={1} width="100%">
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Error}
              onClick={onExec}
            >
                Exec
            </StyledButton>
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Success}
              onClick={onSave}
            >
              {value.actionId ? 'Update' : 'Save'}
            </StyledButton>
          </Stack>
        </CardActions>
      )}
    </DefaultCard>
  );
};