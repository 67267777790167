import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../components/atoms/Button';
import {
  ImageBox,
  MainBox,
  StyledSelectMenu,
} from './CreateUser.styles';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import {
  emailVerifiedMessage,
  getRoleId,
  getRoles,
  getTimeZones,
  hasErrorInCreate,
  isEmailExist,
  namesValidation,
} from '../../../utils/users';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField/TextField';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, Tags } from '../../../components/organisms/Tags';
import { AppUser, FontSizeProps, Role, Tag, Timezone, UserFormType, UserHelperText, UserSectionFields } from '../../../@types';
import { useGetAllActiveRoles } from '../../../queries/roles-query';
import { useGetTimezones } from '../../../queries/timezones-query';
import { useCreateUser } from '../../../queries/user-query';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { EntityType } from '../../../configs/enums';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { UploadButton } from '../../../components/molecules/UploadButton';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { RoutePath } from '../../../@types/route.type';
import { SAMPLEUSER, SAMPLEUSERHELPERTEXT } from '../../../constants/user.constant';
import { SAMPLEROLE } from '../../../constants/role.constant';
import { SAMPLETIMEZONE } from '../../../constants/timezone.constant';
import { ImagePlaceHolder } from '../Users.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { USER_PROFILE_MIME_TYPES } from '../../../configs/envs';

export const CreateUser: React.FC = () => {
  const [user, setUser] = useState<AppUser>(SAMPLEUSER);
  const [helperText, setHelperText] = useState<UserHelperText>(SAMPLEUSERHELPERTEXT);
  const [open, setOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  
  const navigate = useNavigate();

  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);

  const [roles, setRoles] = useState<[Role]>([SAMPLEROLE]);
  const [timezones, setTimezones] = useState<[Timezone]>([SAMPLETIMEZONE]);

  const activeRolesQuery = useGetAllActiveRoles();
  const timeZonesQuery = useGetTimezones();
  const useCreateUserQuery = useCreateUser();
  const addTagsQuery = useAddTags();
  const userTagsQuery = getAllTags(EntityType.TYPE_USER);

  const isError = hasErrorInCreate(user.firstName, user.lastName, user.email, user.timezoneId.toString(), user.systemRoleId.toString());

  TabHandler(`Create User | ${PLATFORM_NAME}`);
  
  useEffect(() => {
    if (activeRolesQuery.data) {
      setRoles(activeRolesQuery.data);
      setUser({ ...user, systemRoleId: getRoleId(activeRolesQuery.data) });
    }

    if (timeZonesQuery.data) {
      setTimezones(timeZonesQuery.data);
    }

    if (userTagsQuery.data) {
      setAllTags(userTagsQuery.data);
    }
  }, [activeRolesQuery.data, timeZonesQuery.data, userTagsQuery.data]);

  const handleFirstNameChange = (text: string) => {
    setHelperText({ ...helperText, firstNameHelperText: namesValidation(text, UserSectionFields.FirstNameField) });
    setUser({ ...user, firstName: text });
  };

  const handleLastNameChange = (text: string) => {
    setHelperText({ ...helperText, lastNameHelperText: namesValidation(text, UserSectionFields.LastNameField) });
    setUser({ ...user, lastName: text });
  };

  const handleEmailChange = (email: string) => {
    setHelperText({ ...helperText, EmailHelperText: emailVerifiedMessage(email) });
    setUser({ ...user, email: email });
  };

  const handleEmailExist = async () => {
    setHelperText({ ...helperText, EmailHelperText: await isEmailExist(user.email) });
  };

  const handleTimezoneChange = (timezoneId: number) => {
    setUser({ ...user, timezoneId: timezoneId });
  };

  const onClickPicture = async (fileList: FileList) => {
    setUser({ ...user, profilePic: URL.createObjectURL(fileList[0]), profilePicDetails: { ...fileList } });
  };

  const onChangeSelectedTags = (value: (string | AutocompleteTagType)[]) => {
    setSelectedTags(value);
  };

  const onChangeNewTags = (value: Tag[]) => {
    setNewTags(value);
  };

  const handleUserCreate = async () => {
    setHelperText({ 
      ...helperText, 
      firstNameHelperText: namesValidation(user.firstName, UserSectionFields.FirstNameField),
      lastNameHelperText: namesValidation(user.lastName, UserSectionFields.LastNameField),
      EmailHelperText: emailVerifiedMessage(user.email),
    });

    if (isError || await isEmailExist(user.email)) {
      setValidate(true);
      return;
    }

    const timeout = (delay: number) => {
      return new Promise((res) => setTimeout(res, delay));
    };

    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    const newUser: any = await useCreateUserQuery.mutateAsync(user);

    await addTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_USER,
      entityId: newUser.id,
      freshTags,
      existingTags
    }).then(async () => {
      setOpen(true);
      await timeout(1000);
      navigate(`/${RoutePath.User}/${newUser.id}`);
    });

    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  if (timeZonesQuery.isLoading || activeRolesQuery.isLoading || userTagsQuery.isLoading) {
    return <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid>;
  }

  return (
    <PageContainer>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        {UserFormType.CreateUser}
      </Typography>
      <MainBox>
        <Grid>
          <Grid>
            <Typography variant={TypographyVariantProps.H6}>General</Typography>
          </Grid>
          <Grid container mt="1px" spacing={GRID_SPACING}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                error={!!helperText.firstNameHelperText}
                helperText={helperText.firstNameHelperText}
                label="First Name"
                value={user.firstName}
                onChange={handleFirstNameChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                error={!!helperText.lastNameHelperText}
                helperText={helperText.lastNameHelperText}
                label="Last Name"
                value={user.lastName}
                onChange={handleLastNameChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                error={!!helperText.EmailHelperText}
                helperText={helperText.EmailHelperText}
                label="Login Email"
                value={user.email}
                onChange={handleEmailChange}
                onBlur={handleEmailExist}
              />
            </Grid>
          </Grid>
          <Grid mt={GRID_SPACING}>
            <UploadButton fileTypes={USER_PROFILE_MIME_TYPES.split(',')} maxFileSize={30} setFileDetails={onClickPicture} 
              label="Upload Profile Picture" icon={<AddPhotoAlternateIcon fontSize={FontSizeProps.Small} />}/>
          </Grid>
          {(user?.profilePic && user?.profilePic !== '') ? (
            <Grid mt={GRID_SPACING}>
              <ImageBox src={user.profilePic} />
            </Grid>
          ):(
            <ImagePlaceHolder />
          )}
          <Grid mt={GRID_SPACING}>
            <Typography variant={TypographyVariantProps.H6}>Additional</Typography>
          </Grid>
          <Grid container mt="1px" rowSpacing={GRID_SPACING} columnSpacing={GRID_SPACING}>
            <Grid item xs={6}>
              <StyledSelectMenu
                required={false}
                id="demo-simple-select-required"
                labelId="demo-simple-select-required-label"
                label="Role *"
                selectedValue={user.systemRoleId ? user.systemRoleId : getRoleId(roles)}
                onChange={(roleId: number) => setUser({ ...user, systemRoleId: roleId })}
                items={getRoles(roles)}
                validate={validate}
                optionalLabelEnabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelectMenu
                required={true}
                id="demo-simple-select-required"
                labelId="demo-simple-select-required-label"
                label="Timezone *"
                selectedValue={user.timezoneId}
                onChange={handleTimezoneChange}
                items={getTimeZones(timezones)}
                validate={validate}
                optionalLabelEnabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Tags
                selectedTags={selectedTags}
                newTags={newTags}
                setSeletedTags={onChangeSelectedTags}
                setNewTags={onChangeNewTags}
                allTags={allTags}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                multiline
                rows={4}
                value={user.notes}
                onChange={(text: string) => setUser({ ...user, notes: text })}
              />
            </Grid>
          </Grid>
          <Grid mt={2}>
            <Button variant={ButtonVariantProps.Primary} onClick={handleUserCreate}>
              Create User
            </Button>
          </Grid>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="User Added!"
            action={action}
          />
        </Grid>
      </MainBox>
    </PageContainer>
  );
};